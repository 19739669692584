<script setup>
import { toRefs } from 'vue';
// import WbButton from '../../../components/account/wb-button.vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  img_url: {
    type: String,
    default: ''
  }
});

const { modelValue, img_url } = toRefs(props);
</script>
<template>
  <teleport to="body">
    <div v-show="modelValue" class="fixed inset-0" style="z-index: 9999">
      <div class="fixed inset-0 flex overflow-auto bg-black bg-opacity-20">
        <div v-show="modelValue" class="relative w-full h-full text-center">
          <img class="h-96 mt-48 mx-auto my-auto" :src="img_url" alt="User Image" />
        </div>
      </div>
    </div>
  </teleport>
</template>
