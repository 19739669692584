<template>
  <CResetPassword v-if="resetPass && isShowModal" />
  <form v-else class="block sm:max-w-[24rem] sm:w-96 w-full" @submit.prevent="onLoginHandler">
    <h3 class="block text-xl md:text-xl text-center mb-6">
      {{ $t('profile.auth') }}
    </h3>
    <p v-if="loginErrorMessage" class="mb-6 text-red-500 font-thin text-sm">
      {{ loginErrorMessage }}
    </p>

    <div class="flex flex-col mb-4">
      <c-input
        ref="usernameRef"
        v-model="loginState.username"
        v-maska="['+998 ## ###-##-##', '+998 ## ###-##-##']"
        name="username"
        :valid="!loginValidator.username.$error"
        placeholder="+998901234567"
        class="w-full"
      >
        {{ $t('profile.phone_number') }}
      </c-input>
      <div class="mb-3 font-thin text-red-500">
        <div v-for="error in loginValidator.username.$errors" :key="error.$uid">
          {{ error.$message }}
        </div>
      </div>
      <c-input
        v-model="loginState.password"
        name="password"
        :valid="!loginValidator.password.$error"
        type="password"
        :placeholder="t('profile.password')"
        eye
        class="w-full"
      >
        {{ $t('profile.password') }}
      </c-input>
      <div class="mb-3 font-thin text-red-500">
        <div v-for="error in loginValidator.password.$errors" :key="error.$uid">
          {{ error.$message }}
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-4">
      <c-button value="customer" :disabled="isLogining" :loading="isLogining">
        {{ $t('profile.login_customer') }}
      </c-button>
      <c-button
        value="executor"
        :disabled="isLogining"
        :loading="isLogining"
        style="background: rgb(251, 146, 60)"
      >
        {{ $t('profile.login_executor') }}
      </c-button>
    </div>
    <div class="mt-3">
      <button
        class="mb-3 text-primary text-sm underline float-right"
        type="button"
        @click="resetPass = true"
      >
        {{ $t('profile.reset_password') }}
      </button>
    </div>
  </form>
</template>

<script setup>
import { useLogin, isAuthentificated } from '@/composables/auth';
import { useRouter } from 'vue-router';
import { ProfileType } from '@/utils/enums';
import useVuelidate from '@vuelidate/core';
import { helpers, required, sameAs } from '@vuelidate/validators';
import { or } from '@vueuse/core';
import { onMounted, reactive, ref, watch } from 'vue';
import CResetPassword from './c-reset-password.vue';
//import store from '../../store/index.js';
import { useI18n } from 'vue-i18n';

const usernameRef = ref(null);
const { t } = useI18n();
const emit = defineEmits(['successful']);
const props = defineProps({
  isShowModal: {
    type: Boolean,
    required: true
  }
});
const router = useRouter();
const { onLogin, isLogining, profile } = useLogin();
const loginState = reactive({
  username: '',
  password: '',
  type: ProfileType.customer
});
const loginRules = reactive({
  username: {
    required: helpers.withMessage(t('profile.phone_number_required'), required)
  },
  password: {
    required: helpers.withMessage(t('profile.password_required'), required)
  },
  type: {
    in: helpers.withMessage(
      t('profile.type_required'),
      or(sameAs(ProfileType.customer), sameAs(ProfileType.executor))
    )
  }
});
const loginExternalResults = reactive({
  username: [],
  password: [],
  type: []
});
const resetPass = ref(false);
const loginValidator = useVuelidate(loginRules, loginState, {
  $externalResults: loginExternalResults
});
const loginErrorMessage = ref();

const onLoginHandler = async (event) => {
  const type = event.submitter.value;
  Object.assign(loginState, { type });

  if (!(await loginValidator.value.$validate())) return;
  if (loginState.username.includes('-')) {
    loginState.username = loginState.username.replace(/\s/g, '').toLowerCase().split('-');
    loginState.username =
      loginState.username?.[0] + loginState.username?.[1] + loginState.username?.[2];
  }
  await onLogin(loginState).catch(({ response }) => {
    const { type, fields, message } = response?.data?.error;

    if (type == 'validation') {
      Object.assign(loginExternalResults, fields);
    }
    if (type == 'auth') {
      loginErrorMessage.value = message;
    }

    throw new Error({ type, fields, message });
  });

  if (isAuthentificated.value) {
    router.push('/account');
  }

  emit('successful', profile.value);
};

watch(
  () => props.isShowModal,
  (val) => {
    // console.log(val);
    if (!val) {
      setTimeout(() => {
        loginState.username = '';
        loginState.password = '';
        loginState.type = ProfileType.customer;
        resetPass.value = false;
        loginValidator.value.$reset();
      }, 500);
    } else {
      setTimeout(() => {
        usernameRef?.value?.input?.focus?.();
        loginValidator.value.$reset();
      }, 200);
    }
  }
);
watch(
  () => loginState.username,
  () => {
    loginExternalResults.username = [];
  }
);
watch(
  () => loginState.password,
  () => {
    loginExternalResults.password = [];
  }
);
watch(
  () => loginState.type,
  () => {
    loginExternalResults.type = [];
  }
);

onMounted(() => {
  loginState.username = '';
  loginState.password = '';
});
</script>
