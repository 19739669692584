<script setup>
import { ref, toRefs, useSlots } from 'vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  }
});

const slots = useSlots();
const { modelValue } = toRefs(props);
const emit = defineEmits(['update:modelValue']);

const closeModal = () => {
  emit('update:modelValue', false);
};

// const showModal = () => {
//   emit('update:modelValue', true);
// };

// const onEscape = e => {
//   e.preventDefault();
//   e.stopPropagation();
//   if (e.keyCode != 27) {
//     return;
//   }
//   closeModal();
// };
const onAfterEnterForContainer = (el) => {
  el.classList.add('backdrop-blur-sm');
};
const container = ref();
// onMounted(() => {
//   container.value.addEventListener('wheel', function (e) {});
// });
</script>
<template>
  <teleport to="body">
    <transition
      enter-from-class="backdrop backdrop-blur-0"
      enter-active-class="transition-all ease-in overflow-hidden duration-200"
      enter-to-class="backdrop-blur-sm"
      leave-from-class="backdrop-blur-sm"
      leave-active-class="transition-all ease-out overflow-hidden duration-200"
      leave-to-class="backdrop-blur-0"
      @after-enter="onAfterEnterForContainer"
    >
      <div
        v-show="modelValue"
        ref="container"
        class="modal fixed inset-0 z-50"
        style="z-index: 9991"
        @wheel.stop
      >
        <div class="fixed inset-0 flex overflow-auto py-10 px-4">
          <transition
            enter-from-class="scale-95 -translate-y-20"
            enter-active-class="transition-all ease-out duration-200 transform"
            enter-to-class="scale-100 translate-y-0"
            leave-from-class="scale-100 translate-y-0"
            leave-active-class="transition-all ease-in duration-200 transform"
            leave-to-class="scale-95 -translate-y-20"
          >
            <div
              v-show="modelValue"
              class="bg-white shadow-lg shadow-primary/50 rounded-md border border-primary/25 relative m-auto sm:w-auto w-full"
            >
              <div v-if="slots.header" class="p-4 border-b border-primary/25">
                <slot :close="closeModal" name="header" />
              </div>
              <div v-if="slots.default" class="p-4">
                <slot :close="closeModal" name="default" />
              </div>
              <div v-if="slots.footer" class="modal-footer p-4 border-t border-primary/25 flex flex-wrap gap-2">
                <slot :close="closeModal" name="footer" />
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </teleport>
</template>
