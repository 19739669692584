<template>
  <div class="relative">
    <div
      class="top-0 absolute w-full bg-white rounded-3xl overflow-hidden -z-10 search-list"
      :style="{
        paddingTop: `${searchContainer?.offsetHeight}px`
      }"
    >
      <ul :class="{ hidden: !isOpen }">
        <li v-if="loader" class="px-4 py-4 text-center">
          <svg
            class="animate-spin h-5 w-5 text-primary mx-auto"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            />
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </li>
        <li
          v-for="service in data?.collection"
          v-else-if="data?.collection?.length > 0"
          :key="service.id"
          class="px-4 py-2 cursor-pointer hover:bg-primary hover:bg-opacity-10 focus:bg-primary focus:bg-opacity-20 focus:animate-pulse"
          tabindex="0"
          @click="navigateTo(service)"
        >
          {{ service.name }}
        </li>
        <li v-else class="py-3 px-4">
          {{ $t('search.not_found') }}
        </li>
      </ul>
    </div>
    <div ref="searchContainer" class="group z-30">
      <input
        ref="searchField"
        v-model="search"
        type="text"
        class="w-full p-3 px-5 rounded-full focus:outline-none group-hover:shadow-[0_0_10px_10px_rgb(249,115,22,.25)] focus:!shadow-[0_0_10px_10px_rgb(249,115,22,.5)]"
        :class="{
          'shadow-[0_0_10px_10px_rgb(249,115,22,.25)]': isOpen
        }"
        @keypress.enter="data?.collection?.length > 0 ? navigateToButton(data.collection) : ''"
        @click.prevent.stop="search?.length > 0 ? (isOpen = true) : ''"
      />
      <button
        type="button"
        class="p-2 px-4 absolute inset-y-1 right-1 bg-orange-400 rounded-full text-white"
        @click="data?.collection?.length > 0 ? navigateToButton(data.collection) : ''"
      >
        <span class="md:block hidden"> {{ $t('search.search_btn') }}</span>
        <span class="md:hidden block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue';
import debounce from 'lodash.debounce';
import { useAxios } from '@vue-composable/axios';
import { useRouter } from 'vue-router';
import Typed from 'typed.js';
import { OrderDataSession } from '@/composables/orders/instance&storage';
const { SaveSession } = OrderDataSession();
const searchContainer = ref(null);
const search = ref('');
const { data, exec } = useAxios();

const isOpen = ref(false);
const loader = ref(true);

watch(data, () => {
  loader.value = false;
});

watch(search, () => {
  if (search?.value?.length > 2) isOpen.value = true;
  else isOpen.value = false;
});

watch(
  search,
  debounce(
    () =>
      exec({
        url: process.env.VUE_APP_API_URL + '/fika/freelancer/v1.0.1/services',
        params: { search: search.value, with: ['category'], count: ['children'] },
        headers: {
          'Accept-Language': sessionStorage.getItem('lang') || 'ru'
        }
      }),
    200
  )
);
const router = useRouter();
const navigateTo = (service) => {
  SaveSession({
    parent_service_id: service.id,
    services: [service.slug],
    category: service.category.slug
  });
  if (service.children_count > 0) {
    router.push(`/${service.category.slug}/${service.slug}/select`);
  } else {
    router.push(`/${service.category.slug}/${service.slug}/work-place`);
  }
};
const navigateToButton = (service) => {
  service.forEach((arr) => {
    if (arr.name == search.value) {
      SaveSession({
        parent_service_id: arr.id,
        services: [arr.slug],
        category: arr.category.slug
      });
      if (arr.children_count > 0) {
        router.push(`/${arr.category.slug}/${arr.slug}/select`);
      } else {
        router.push(`/${arr.category.slug}/${arr.slug}/work-place`);
      }
    }
  });
};
const escapePressedListener = (e) => {
  if (e.keyCode == 27) isOpen.value = false;
};
const outsideClickListener = () => {
  isOpen.value = false;
};
onMounted(() => {
  document.addEventListener('keyup', escapePressedListener);
  document.addEventListener('click', outsideClickListener);
});
onUnmounted(() => {
  document.removeEventListener('keyup', escapePressedListener);
  document.removeEventListener('click', outsideClickListener);
});

const curentLang = sessionStorage.getItem('lang') || 'ru';
const searchField = ref(null);
onMounted(() => {
  if (curentLang == 'ru') {
    new Typed(searchField.value, {
      attr: 'placeholder',
      strings: [
        'Например: SMM',
        'Например: Детский врач',
        'Например: Уборка квартиры',
        'Например: Маникюр',
        'Например: Ремонт телефонов',
        'Например: Медсестра',
        'Например: Бухгалтеры',
        'Например: Химчистка',
        'Например: Создание сайтов',
        'Например: SMM',
        'Например: Детский врач',
        'Например: Уборка квартиры',
        'Например: Маникюр',
        'Например: Ремонт телефонов',
        'Например: Медсестра',
        'Например: Бухгалтеры',
        'Например: Химчистка',
        'Например: Создание сайтов',
        'Например: SMM',
        'Например: Детский врач',
        'Например: Уборка квартиры',
        'Например: Маникюр',
        'Например: Ремонт телефонов',
        'Например: Медсестра',
        'Например: Бухгалтеры',
        'Например: Химчистка',
        'Например: Создание сайтов',
        'Например: SMM',
        'Например: Детский врач',
        'Например: Уборка квартиры',
        'Например: Маникюр',
        'Например: Ремонт телефонов',
        'Например: Медсестра',
        'Например: Бухгалтеры',
        'Например: Химчистка',
        'Например: Создание сайтов'
      ],
      smartBackspace: true,

      cursorChar: '|',
      typeSpeed: 60,
      loop: true,
      backSpeed: 40
    });
  }
  if (curentLang == 'uz') {
    new Typed(searchField.value, {
      attr: 'placeholder',
      strings: [
        'Masalan: SMM',
        'Masalan: Bolalar shifokori',
        'Masalan: Uyni tozalash',
        'Masalan: Manikyur',
        "Masalan: Telefonni ta'mirlash",
        'Masalan: Hamshira',
        'Masalan: Buxgalterlar',
        'Masalan: Quruq tozalash',
        'Masalan: Veb-sayt yaratish',
        'Masalan: SMM',
        'Masalan: Bolalar shifokori',
        'Masalan: Uyni tozalash',
        'Masalan: Manikyur',
        "Masalan: Telefonni ta'mirlash",
        'Masalan: Hamshira',
        'Masalan: Buxgalterlar',
        'Masalan: Quruq tozalash',
        'Например: Veb-sayt yaratish',
        'Например: SMM',
        'Masalan: Bolalar shifokori',
        'Masalan: Uyni tozalash',
        'Masalan: Manikyur',
        "Masalan: Telefonni ta'mirlash",
        'Masalan: Hamshira',
        'Masalan: Buxgalterlar',
        'Masalan: Quruq tozalash',
        'Masalan: Veb-sayt yaratish',
        'Masalan: SMM',
        'Masalan: Bolalar shifokori',
        'Masalan: Uyni tozalash',
        'Masalan: Manikyur',
        "Masalan: Telefonni ta'mirlash",
        'Masalan: Hamshira',
        'Masalan: Buxgalterlar',
        'Masalan: Quruq tozalash',
        'Masalan: Veb-sayt yaratish'
      ],
      smartBackspace: true,

      cursorChar: '|',
      typeSpeed: 60,
      loop: true,
      backSpeed: 40
    });
  }
  if (curentLang == 'en') {
    new Typed(searchField.value, {
      attr: 'placeholder',
      strings: [
        'I need help with: SMM',
        "I need help with: Children's doctor",
        'I need help with: Home cleaning',
        'I need help with: Manicure',
        'I need help with: Phone repair',
        'I need help with: Nurse',
        'I need help with: Accountants',
        'I need help with: Dry cleaning',
        'I need help with: Website development',
        'I need help with: SMM',
        "I need help with: Children's doctor",
        'I need help with: Home cleaning',
        'I need help with: Manicure',
        'I need help with: Phone repair',
        'I need help with: Nurse',
        'I need help with: Accountants',
        'I need help with: Dry cleaning',
        'I need help with: Website development',
        'I need help with: SMM',
        "I need help with: Children's doctor",
        'I need help with: Home cleaning',
        'I need help with: Manicure',
        'I need help with: Phone repair',
        'I need help with: Nurse',
        'I need help with: Accountants',
        'I need help with: Dry cleaning',
        'I need help with: Website development',
        'I need help with: SMM',
        "I need help with: Children's doctor",
        'I need help with: Home cleaning',
        'I need help with: Manicure',
        'I need help with: Phone repair',
        'I need help with: Nurse',
        'I need help with: Accountants',
        'I need help with: Dry cleaning',
        'I need help with: Website development'
      ],
      smartBackspace: true,

      cursorChar: '|',
      typeSpeed: 60,
      loop: true,
      backSpeed: 40
    });
  }

  if (curentLang == 'oz') {
    new Typed(searchField.value, {
      attr: 'placeholder',
      strings: [
        'Масалан: СММ',
        'Масалан: Болалар шифокори',
        'Масалан: Уйни тозалаш',
        'Масалан: Маникюр',
        'Масалан: Телефонни таъмирлаш',
        'Масалан: Ҳамшира',
        'Масалан: Бухгалтерлар',
        'Масалан: Қуруқ тозалаш',
        'Масалан: Веб-сайт яратиш',
        'Масалан: СММ',
        'Масалан: Болалар шифокори',
        'Масалан: Уйни тозалаш',
        'Масалан: Маникюр',
        'Масалан: Телефонни таъмирлаш',
        'Масалан: Ҳамшира',
        'Масалан: Бухгалтерлар',
        'Масалан: Қуруқ тозалаш',
        'Например: Веб-сайт яратиш',
        'Например: СММ',
        'Масалан: Болалар шифокори',
        'Масалан: Уйни тозалаш',
        'Масалан: Маникюр',
        'Масалан: Телефонни таъмирлаш',
        'Масалан: Ҳамшира',
        'Масалан: Бухгалтерлар',
        'Масалан: Қуруқ тозалаш',
        'Масалан: Веб-сайт яратиш',
        'Масалан: СММ',
        'Масалан: Болалар шифокори',
        'Масалан: Уйни тозалаш',
        'Масалан: Маникюр',
        'Масалан: Телефонни таъмирлаш',
        'Масалан: Ҳамшира',
        'Масалан: Бухгалтерлар',
        'Масалан: Қуруқ тозалаш',
        'Масалан: Веб-сайт яратиш'
      ],
      smartBackspace: true,

      cursorChar: '|',
      typeSpeed: 60,
      loop: true,
      backSpeed: 40
    });
  }
});
</script>
