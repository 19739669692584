import useProfile from '@/composables/profile';
import axios from '@/utils/axios';
// import { ref } from 'vue';
const { profile } = useProfile();

export const getById = (id, params = {}, config) => {
  return axios.get(`fika/freelancer/v1.0.1/orders/${id}`, { params, ...config });
};

export const get = async (params) => {
  return axios.get(`/fika/workbase/v1.0.1/profile/${profile.value.id}/orders`, params);
};

/**
 *
 * @param  data
 * @param {import("axios").AxiosRequestConfig} config
 * @returns {Promise<import("axios").AxiosResponse>}
 */
export const create = (data, config = {}) => {
  return axios.post('fika/freelancer/v1.0.1/orders', data, config);
};

export const update = (
  id,
  // eslint-disable-next-line no-undef
  data = { services, name, address, lat, lng, is_online, at_customer, at_executor }
) => {
  return axios.post(`fika/freelancer/v1.0.1/orders/${id}`, data);
};

export const deleteById = (id, params, config) => {
  return axios.delete(`fika/freelancer/v1.0.1/orders/${id}`, { params, ...config });
};

export const publish = (id, data = {}, config = {}) => {
  return axios.post(`fika/freelancer/v1.0.1/orders/${id}/publish`, data, config);
};

export const reply = (id, data = {}, config = {}) =>
  axios.post(`fika/freelancer/v1.0.1/orders/${id}/replies`, data, config);
