import { changePass } from '@/api/auth';
import { readonly, ref } from 'vue';
const isLoading = ref(false);
const isLoaded = ref(false);
const result = ref(null);
export default function useResetApi() {
  const onChange = async ({ phone, password, password_confirmation }) => {
    isLoading.value = true;
    await changePass({ phone, password, password_confirmation })
      .then((response) => response?.data)
      .then((data) => {
        result.value = data;
        isLoaded.value = true;
      })
      .finally(() => (isLoading.value = false));
  };
  return {
    onChange,
    isLoading: readonly(isLoading),
    result: readonly(result),
    isLoaded: readonly(isLoaded)
  };
}
