<template>
  <div
    class="xl:mb-6 mb-4 flex items-center justify-center bg-primary py-2 px-5 w-full rounded-lg transition duration-500 ease-in-out text-white hover:bg-primary/80 focus:outline-none go-back"
    @click="onRouterGo"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="lg:w-6 w-4 lg:h-6 h-4 mr-1"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
      />
    </svg>
    {{ $t('pages.myprofile.back') }}
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();

const onRouterGo = () => {
  if (router?.options?.history?.state?.back) {
    router.go(-1)
  } else {
    router.push('/')
  }
}
</script>

<style>
.go-back {
  cursor: pointer;
}
</style>