import axios from '@/utils/axios';

export const getSpecialty = async (userId) => {
  return axios.get(`fika/workbase/v1.0.1/speciality/${userId}/all`);
};
export const removeSpecialty = async (specialityId) => {
  return axios.delete(`fika/workbase/v1.0.1/speciality/${specialityId}/delete`);
};
export const getServicesList = () => {
  return axios.get('/fika/freelancer/v1.0.1/categories?&with[]=image');
};
export const getSpecialtyDetail = async (specialityId) => {
  return axios.get(`fika/workbase/v1.0.1/speciality/${specialityId}/one`);
};
export const createSpecialty = async (userId, payload) => {
  return axios.post(`fika/workbase/v1.0.1/speciality/${userId}/all`, payload);
};
export const updateSpecialty = async (payload) => {
  return axios.post(
    `fika/workbase/v1.0.1/speciality/update/${payload.specialityId}/one`,
    payload.data
  );
};

export const StatusOneSpecialty = async (payload) => {
  return axios.put(`/fika/workbase/v1.0.1/speciality/status/${payload.specialityId}/speciality`);
};
export const StatusAllSpecialty = async (payload) => {
  return axios.put(
    `/fika/workbase/v1.0.1/speciality/status/${payload.user_id}/${payload.specialityId}/category`
  );
};
export const UserStatusAllSpecialty = async (payload) => {
  return axios.put(`/fika/workbase/v1.0.1/speciality/status/speciality/${payload.user_id}/user`);
};

export const getServicesChilds = (payload) => {
  return axios.get(
    `fika/freelancer/v1.0.1/categories/${payload.id}/restnew/${payload.slug}?with[]=services&with[]=image`
  );
};
export const getGransonServices = (slug) => {
  return axios.get(
    `/fika/freelancer/v1.0.1/services?parent=${slug}&with[]=children`
  );
};
