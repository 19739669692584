import { register } from '@/api/auth';
import { readonly, ref } from 'vue';
import { profile as profileRef } from '../profile';
import { token as tokenRef } from './token';
import store from '@/store';
const isRegistering = ref(false);
const isRegistered = ref(false);
const isLogining = ref(false);
const isLogined = ref(false);

export default function useRegister() {
  const onRegister = async ({
    name,
    surname,
    patronymic,
    username,
    email,
    type,
    password,
    passwordConfirmation,
    password_confirmation
  }) => {
    isRegistering.value = true;
    await register({
      name,
      surname,
      patronymic,
      username,
      email,
      type,
      password,
      password_confirmation: password_confirmation || passwordConfirmation
    })
      .then((response) => response?.data)
      .then((data) => {
        // $cookies.set('token', data.token.value, '1d');
      //  profileRef.value = profile;
        const { token, profile } = data;
        tokenRef.value = token.value;
        profileRef.value = profile;
        // $cookies.set("token" , token.value,"1d")
         store.commit('changeImgUrl', {
           avatar: profile?.avatar || null,
           avatar_thumb: profile.avatar_thumb
        });
        store.commit('changeProfile', profile);
        isRegistered.value = true;
        isLogined.value = true;
        return readonly(profileRef);
      })
      .finally(() => (isRegistering.value = false));
  };
  return {
    onRegister,
    isRegistering: readonly(isRegistering),
    profile: readonly(profileRef),
    isRegistered: readonly(isRegistered),
    isLogining: readonly(isLogining),
    isLogined: readonly(isLogined)
  };
}
