<template>
  <div class="relative group">
    <input
      :id="fieldName"
      ref="input"
      :value="modelValue"
      :type="isEyeClicked ? 'text' : type"
      :name="name"
      :disabled="disabled"
      :placeholder="placeholder"
      :step="step"
      :min="min"
      :max="max"
      :autofocus="autofocus"
      class="focus:outline-none px-3 py-2 rounded-md border border-primary/20 focus:border-primary placeholder:transition placeholder:duration-200 placeholder:select-none focus:placeholder:text-gray-400 appearance-none transition duration-200 focus:shadow-md focus:shadow-primary/10 disabled:bg-gray-50 disabled:cursor-not-allowed autofill:!bg-yellow-200 peer"
      :class="[
        { 'placeholder:text-transparent': !!$slots.default },
        { '!border-red-500/50': isInvalid },
        { 'focus:!border-red-500': isInvalid },
        inputClass
      ]"
      @input="emit('update:modelValue', $event.target.value)"
    />
    <label
      v-if="$slots.default"
      :for="fieldName"
      class="text-primary absolute pointer-events-none rounded-full -translate-y-1/2 peer-focus:top-0 peer-focus:text-sm peer-focus:px-1 peer-focus:!left-2 peer-focus:bg-white transition-all duration-200 peer-disabled:before:top-1/2 peer-disabled:before:h-1/2 peer-disabled:before:w-full peer-disabled:before:absolute peer-disabled:before:bg-gray-50 peer-disabled:before:-z-10 peer-disabled:before:left-0"
      :class="{
        'top-1/2': !!!modelValue,
        'text-sm': !!modelValue,
        'px-1': !!modelValue,
        'left-4': !!!modelValue,
        'left-2': !!modelValue,
        'bg-white': !!modelValue,
        '!text-red-500': isInvalid
      }"
    >
      <slot />
    </label>
    <label
      v-if="$slots.prefix"
      ref="prefixContainer"
      :for="fieldName"
      class="absolute flex pointer-events-none items-center inset-y-0 pl-3 pr-3 border border-transparent transition duration-200"
      :class="{
        'text-transparent group-focus-within:text-primary': !!!modelValue,
        'text-primary': !!modelValue
      }"
    >
      <slot name="prefix" />
    </label>
    <label
      v-if="$slots.lprefix"
      ref="lPrefixContainer"
      :for="fieldName"
      class="absolute flex pointer-events-none items-center inset-y-0 right-0 pl-3 pr-2 border border-transparent transition duration-200"
      :class="{
        'text-transparent group-focus-within:text-primary': !!!modelValue,
        'text-primary': !!modelValue
      }"
    >
      <slot name="lprefix" />
    </label>
    <button
      v-if="eye && type == 'password'"
      ref="eyeContainer"
      class="absolute inset-y-0 right-0 text-primary pr-3 pl-2 transition duration-200"
      :class="{
        'text-transparent group-focus-within:text-primary': !!!modelValue,
        'text-primary': !!modelValue
      }"
      type="button"
      @click="isEyeClicked = !isEyeClicked"
    >
      <h-eye :class="{ hidden: isEyeClicked }" />
      <h-eye-off :class="{ hidden: !isEyeClicked }" />
    </button>
  </div>
</template>
<script setup>
import { onMounted, ref, computed, toRefs, onUnmounted } from 'vue';

const props = defineProps({
  name: {
    type: String,
    default() {
      return `${parseInt(Math.random() * 1000000)}`;
    }
  },
  modelValue: {
    type: [String, Number],
    required: true,
    default: null
  },
  placeholder: {
    type: [String, Number]
  },
  type: {
    type: [String],
    default: 'text',
    validator(value) {
      return ['text', 'number', 'password', 'email'].includes(value);
    }
  },
  class: {
    type: [Object, String, Array],
    default: function () {
      return {};
    }
  },
  valid: {
    type: Boolean,
    default: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  autofocus: {
    type: Boolean,
    default: false
  },
  step: {
    type: [Number, String],
    default: null
  },
  min: {
    type: [Number, String],
    required: false
  },
  max: {
    type: [Number, String],
    default: null
  },
  eye: {
    type: Boolean,
    default: false
  }
});
const { class: inputClass, modelValue, name, valid, disabled, autofocus } = toRefs(props);

const isInvalid = computed(() => !valid.value);
const input = ref(null);
const prefixContainer = ref();
const lPrefixContainer = ref();
const eyeContainer = ref();
const isEyeClicked = ref(false);
const fieldName = computed(() => `${name.value}${parseInt(Math.random() * 9999999)}Field`);
const curentLang = sessionStorage.getItem('lang') || 'ru';

onMounted(() => {
  if (autofocus.value) {
    input.value.focus();
  }
  setInputPadding();
  //document.addEventListener("DOMSubtreeModified", setInputPadding);
});

onUnmounted(() => {
  //document.removeEventListener("DOMSubtreeModified", setInputPadding);
});

const setInputPadding = () => {
  if (prefixContainer.value) {
    if (curentLang == 'ru') {
      input.value.style.paddingLeft = `${prefixContainer.value.clientWidth || 44}px`;
      input.value.style.paddingLeft = `${prefixContainer.value.clientWidth || 44}px`;
    } else {
      input.value.style.paddingLeft = `${prefixContainer.value.clientWidth || 70}px`;
      input.value.style.paddingLeft = `${prefixContainer.value.clientWidth || 70}px`;
    }
  }
  if (lPrefixContainer.value) {
    if (curentLang == 'ru') {
      input.value.style.paddingRight = `${lPrefixContainer.value.clientWidth || 44}px`;
      input.value.style.paddingRight = `${lPrefixContainer.value.clientWidth || 44}px`;
    } else {
      input.value.style.paddingRight = `${lPrefixContainer.value.clientWidth || 70}px`;
      input.value.style.paddingRight = `${lPrefixContainer.value.clientWidth || 70}px`;
    }
  }
  if (eyeContainer.value) {
    input.value.style.paddingRight = `${eyeContainer.value.clientWidth || 44}px`;
  }
};
const emit = defineEmits(['update:modelValue']);
defineExpose({
  input
});
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  @apply hidden m-0;
}
</style>
