import axios from '@/utils/axios';
/**
 * @param {{with: string[]}} [params]
 *
 */
export const get = (params = { with: [] }) =>
  axios.get('/fika/workbase/v1.0.1/profile', { params });

export const update = (payload, user_id) => {
  return axios.put(`/fika/workbase/v1.0.1/profile/${user_id}`, payload);
};
export const getProfile = (username) => {
  return axios.get(`/fika/workbase/v1.0.1/myprofile/${username}`);
};
