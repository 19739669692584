<template>
  <div>
    <div id="notification" class="relative notification">
      <button
        class="xl:flex my-2 border transition-all duration-300 ease-out hover:ease-in rounded-full items-center px-3 py-2"
        :class="headerType"
        @click.prevent.stop="show = !show"
      >
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M168,224a8,8,0,0,1-8,8H96a8,8,0,1,1,0-16h64A8,8,0,0,1,168,224Zm53.85-32A15.8,15.8,0,0,1,208,200H48a16,16,0,0,1-13.8-24.06C39.75,166.38,48,139.34,48,104a80,80,0,1,1,160,0c0,35.33,8.26,62.38,13.81,71.94A15.89,15.89,0,0,1,221.84,192ZM208,184c-7.73-13.27-16-43.95-16-80a64,64,0,1,0-128,0c0,36.06-8.28,66.74-16,80Z"/></svg>
        </span>
        <span v-if="isActiveNotifications" class="notification-in"></span>
      </button>
      <div
        v-show="show"
        class="notification-main absolute right-0 z-50 mt-5 bg-white rounded-md shadow-xl text-center"
      >
        <div class="notification-title">
          <h3>{{ t('notif.title') }}</h3>
          <button class="absolute top-2 right-3 bg-gray-300 hover:bg-primary hover:text-white transition duration-200 rounded-full p-2"><svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="w-3 h-3"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg></button>
        </div>
        <div v-if="notifications?.data?.length > 0" class="notification-items">
          <a v-for="notif in notifications?.data" :key="notif.id" href="#!" @click="updateStatusNotification(notif)" class="notification-item">
            <div v-if="notif.avatar" class="notification-item__avatar">
              <img :src="notif.avatar" alt="">
            </div>
            <div class="notification-item__info">
              <span>{{ t('notif.'+notif.type_push)}} <small :class="notif.status == 'active' ? 'new':''">{{notif.created_at}}</small></span>
              <p>{{notif.message}}</p>
            </div>
          </a>  
        </div>
        <div v-else class="notification-items">
            <p class="text-black">{{ t('notif.not_found') }}</p>
        </div>
        <div class="notification-foot">
          <router-link to="/notifications" >{{ t('notif.all') }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
import axios from '@/utils/axios';
import store from '../../store/index.js';
const show = ref(false);
const route = useRoute();
//const router = useRouter();
import { useI18n } from 'vue-i18n';
const user_type = computed(() => store?.state?.profileStore?.type);
const { t } = useI18n();

const escapePressedListener = (e) => {
  if (e.keyCode == 27) show.value = false;
};
const outsideClickListener = () => {
  show.value = false;
};
onMounted( async () => {
  document.addEventListener('keyup', escapePressedListener);
  document.addEventListener('click', outsideClickListener);
  await store.dispatch('requestgetNotification');
  await checkActiveNotifications()
});

onUnmounted(() => {
  document.removeEventListener('keyup', escapePressedListener);
  document.removeEventListener('click', outsideClickListener);
});


const headerType = computed(() => route.meta?.header || 'secondary');
const notifications = computed(() => store?.getters?.notificationList);
// const notifications = ref(null);
const isActiveNotifications = ref(false);
// const token = localStorage.getItem('token');

function checkActiveNotifications(){
  let items = notifications.value.data;
  isActiveNotifications.value = false
  items.forEach(element => {
    if(element?.status == 'active'){
      isActiveNotifications.value = true
    }
  });
};

// async function getNotification() {  
//   try {
//     let res = await axios
//     .get(process.env.VUE_APP_API_URL + '/fika/workbase/v1.0.1/profile/notification?perPage=5', {
//       headers: {
//         Authorization: 'Bearer' + token,
//         'x-access-token': token,
//         // user_type: user_type.value
//       }
//     })
//     notifications.value = res.data
//   } catch (error) {
//     console.log(error)
//   }
// };
watch(
  user_type,
  async (newValue, oldValue) => {
    if(oldValue){
      await store.dispatch('requestgetNotification');
      await checkActiveNotifications();
    }
  },
  { immediate: true }
)
const updateStatusNotification = async (val) => {
  if (val.status == 'active'){
    const res = await axios.put(`/fika/workbase/v1.0.1/profile/notification/status/${val.id}`); 
    
    if(res.data){
       window.location = val.link;
    }
  }else{
     window.location = val.link;
  } 
  await store.dispatch('requestgetNotification');
  await checkActiveNotifications();
};
</script>
