<template>
  <svg
    class="w-6 h-6 xl:m-auto mr-3 fill-current"
    viewBox="0 0 22 23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4507 0.0126953H2.54926C1.14354 0.0126953 0 1.15713 0 2.56394V16.0957C0 17.5025 1.14354 18.647 2.54926 18.647H7.2639L10.3924 21.7781C10.7278 22.1137 11.2721 22.1137 11.6076 21.7781L14.7361 18.647H19.4507C20.8565 18.647 22 17.5025 22 16.0957V2.56394C22 1.15713 20.8565 0.0126953 19.4507 0.0126953ZM20.2812 16.0957C20.2812 16.554 19.9086 16.9269 19.4507 16.9269H14.3803C14.1523 16.9269 13.9336 17.0176 13.7725 17.1788L11 19.9535L8.22751 17.1788C8.06638 17.0176 7.84767 16.927 7.6199 16.927H2.54926C2.09137 16.927 1.71875 16.554 1.71875 16.0957V2.56394C1.71875 2.1057 2.09137 1.73279 2.54926 1.73279H19.4507C19.9086 1.73279 20.2812 2.1057 20.2812 2.56394V16.0957ZM14.3908 6.63337C14.4513 7.94914 13.7705 9.15556 12.6138 9.78195C12.1486 10.0341 11.8594 10.5046 11.8594 11.01V11.0213C11.8594 11.4963 11.4747 11.8813 11 11.8813C10.5253 11.8813 10.1406 11.4963 10.1406 11.0213C10.1418 11.0169 10.0612 9.20881 11.7958 8.26931C12.3673 7.95972 12.7038 7.36323 12.6739 6.71249C12.6347 5.85748 11.9346 5.15685 11.0802 5.11754C10.1149 5.07319 9.32422 5.84052 9.32422 6.79278C9.32422 7.26782 8.93951 7.65283 8.46484 7.65283C7.99017 7.65283 7.60547 7.26782 7.60547 6.79278C7.60547 4.84978 9.22452 3.30808 11.1591 3.3993C12.903 3.47942 14.3108 4.89077 14.3908 6.63337ZM11.8594 13.5441V14.4042C11.8594 14.8792 11.4747 15.2642 11 15.2642C10.5253 15.2642 10.1406 14.8792 10.1406 14.4042V13.5441C10.1406 13.0691 10.5253 12.6841 11 12.6841C11.4747 12.6841 11.8594 13.0691 11.8594 13.5441Z"
    />
  </svg>
</template>

<script setup></script>

<style></style>
