<script setup>
import { Autoplay, EffectFade } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

const onSlideChange = () => {};
</script>

<template>
  <section class="bg-gray-50 md:py-12 sm:py-8 py-4">
    <div
      class="container px-4 sm:px-0 md:flex items-center justify-between md:px-4 2xl:px-20 mx-auto"
    >
      <div class="">
        <h2
          class="lg:text-2xl text-xl text-center md:text-left md:text-3xl font-medium pb-4 md:pb-8"
          v-html="$t('home.now_all_services')"
        />
        <div class="flex justify-center md:justify-start">
          <a class="mr-4 transition-all duration-500 transform hover:scale-110" href="#"
            ><img class="w-30 h-10" src="@/assets/temp/apple.png" alt=""
          /></a>
          <a class="transition-all duration-500 transform hover:scale-110" href="#">
            <img class="w-30 h-10" src="@/assets/temp/android.png" alt=""
          /></a>
        </div>
      </div>
      <div class="relative mx-auto w-7/12 md:w-5/12 mt-5 lg:my-0">
        <!-- <div class="swiper mySwiper w-full">
                    <div class="swiper-wrapper mx-auto">
                        <div class="swiper-slide"><img src="" alt="" /></div>
                        <div class="swiper-slide"><img src="" alt="" /></div>
                        <div class="swiper-slide"><img src="" alt="" /></div>
                    </div>
                </div> -->
        <swiper
          :slides-per-view="1"
          :space-between="0"
          :modules="[EffectFade, Autoplay]"
          :loop="true"
          :effect="'fade'"
          :autoplay="{
            delay: 7000,
            disableOnInteraction: false
          }"
          @swiper="onSwiper"
          @slide-change="onSlideChange"
        >
          <swiper-slide>
            <img src="@/assets/temp/sw-1.webp" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/temp/sw-2.webp" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/temp/sw-3.webp" alt="" />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>
</template>
