import { create, update, get } from '@/api/orders';
import { useLocalStorage, useSessionStorage } from '@vueuse/core';
import { computed, readonly, ref } from 'vue';
//const profile = JSON.parse(localStorage.getItem('profile'))
import useProfile from '@/composables/profile';
const draftedOrders = useLocalStorage('drafted', {});
const Order = useSessionStorage('current_order', { services: null });
const orders = ref([]);
const isCreating = ref(false);
const isLoaded = ref(false);
const isLoading = ref(false);
const createPromise = ref(null);

export function useOnGetApi() {
  const onGet = async (params, config) => {
    isLoading.value = true;
    get(params, config)
      .then((response) => response.data)
      .then((data) => {
        orders.value = data;
        isLoaded.value = true;
      })
      .finally(() => {
        isLoading.value = false;
      });
  };
  return {
    isLoaded,
    isLoading,
    orders,
    onGet
  };
}

export function useCreateOrderApi(categorySlug) {
  draftedOrders.value[categorySlug] = categorySlug;
  const isCreated = ref(!!draftedOrders.value[categorySlug]);
  const onCreate = (data = { services: [], category: null }) => {
    isCreating.value = true;
    createPromise.value = create(data)
      .then((response) => response.data)
      .then((data) => {
        isCreated.value = true;
        draftedOrders.value[categorySlug] = data.order;
        return readonly(computed(() => draftedOrders.value[categorySlug]));
      })
      .finally(() => (isCreating.value = false));
    return createPromise.value;
  };

  return {
    onCreate,
    isCreated: readonly(isCreated),
    isCreating: readonly(isCreating),
    promise: readonly(createPromise),
    draftedOrders: readonly(draftedOrders),
    currentDraftedOrder: readonly(computed(() => draftedOrders.value[categorySlug]))
  };
}

export function OrderDataSession() {
  const SaveSession = (data) => {
    Order.value = {
      address: data.address || null,
      lat: data.lat || null,
      lng: data.lng || null,
      ...Order.value,
      ...data
    };
  };
  const destroyCurrentOrder = () => {
    Order.value = { services: null };
  };
  return {
    SaveSession,
    destroyCurrentOrder,
    currentOrder: Order
  };
}

const isUpdating = ref(false);

const isUpdated = ref(false);

const updatePromise = ref(null);

export function useUpdateOrderApi(categorySlug) {
  const onUpdate = (
    data = {
      services: [],
      name: null,
      description: null,
      price: null,
      price_unit: null,
      price_from: null,
      price_up_to: null,
      is_ranged_price: null,
      started_at: null,
      ended_at: null,
      is_online: null,
      at_executor: null,
      at_customer: null,
      exit_locations: null,
      address: null,
      lat: null,
      lng: null
    }
  ) => {
    isUpdating.value = true;
    isUpdated.value = false;
    updatePromise.value = update(draftedOrders.value[categorySlug]?.id, data)
      .then((response) => response.data)
      .then((data) => {
        draftedOrders.value[categorySlug] = data.order;
        isUpdated.value = true;
      })
      .finally(() => (isUpdating.value = false));
    return updatePromise.value;
  };
  return {
    isUpdated: readonly(isUpdated),
    isUpdating: readonly(isUpdating),
    promise: readonly(updatePromise),
    onUpdate,
    draftedOrders: readonly(draftedOrders),
    currentDraftedOrder: readonly(computed(() => draftedOrders.value[categorySlug]))
  };
}

const isPublishing = ref(false);

const publishPromise = ref(null);
export function usePublishOrderApi(categorySlug) {
  const isPublished = ref(false);
  const { profile } = useProfile();
  const onPublish = () => {
    isPublishing.value = true;
    return (publishPromise.value = create({ ...Order.value, customer_id: profile.value?.id })
      .then((response) => response.data, (isPublished.value = true))
      .then((order) => {
        return order;
      })
      .finally(() => (isPublishing.value = false)));
  };
  return {
    isPublishing: readonly(isPublishing),
    isPublished: readonly(isPublished),
    promise: readonly(publishPromise),
    onPublish,
    draftedOrders: readonly(Order.value),
    currentDraftedOrder: readonly(computed(() => draftedOrders.value[categorySlug]))
  };
}
