import { getMarqueeList } from '@/api/marquee';
import { readonly, ref } from 'vue';

const marquee = ref(null);
const errorMarquee = ref(null);
const isLoadingMarquee = ref(false);
const isLoadedMarquee = ref(false);

export default function useMarqueeList() {
  const exec = async () => {
    isLoadingMarquee.value = true;
    await getMarqueeList()
      .then((response) => response?.data)
      .then((data) => {
        marquee.value = data;
        isLoadingMarquee.value = false;
        isLoadedMarquee.value = true;
      })
      .catch((errorObj) => {
        errorMarquee.value = errorObj?.response?.data;
      });
  };

  return {
    marquee: marquee,
    isLoadedMarquee: readonly(isLoadedMarquee),
    isLoadingMarquee: readonly(isLoadingMarquee),
    errorMarquee: readonly(errorMarquee),
    exec
  };
}
