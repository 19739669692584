import {
  getSpecialty,
  getServicesList,
  getServicesChilds,
  getGransonServices,
  getSpecialtyDetail,
  removeSpecialty,
  updateSpecialty,
  StatusAllSpecialty,
  StatusOneSpecialty,
  UserStatusAllSpecialty
} from '@/api/specialties.js';

const state = {
  specialtiesModalOpen: false,
  specialtyList: [],
  servicesList: [],
  grandSonsServiceList: [],
  childServices: null,
  refreshChildServices: true,
  openSelectOne: true,
  mainService: null,
  detail: null
};

const mutations = {
  specialtiesModal(state, payload) {
    state.mainService = payload || null;
    state.specialtiesModalOpen = !state.specialtiesModalOpen;
  },
  setSpecialty(state, data) {
    state.specialtyList = null;
    state.specialtyList = data;
  },
  setServicesList(state, data) {
    state.servicesList = data;
  },
  setChildServices(state, data) {
    state.childServices = data;
  },
  setGrandsons(state, data) {
    state.grandSonsServiceList = data;
  },
  setDetail(state, data) {
    state.detail = data;
  }
};
const actions = {
  requestgetSpecialty({ commit, dispatch }) {
    const profileId = localStorage.profile && JSON.parse(localStorage.profile).id;
    return getSpecialty(profileId)
      .then(async (res) => {
        await dispatch('requestGetSerivices');
        commit('setSpecialty', res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  requestGetSerivices({ commit }) {
    return getServicesList()
      .then((res) => {
        commit('setServicesList', res.data.collection);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  getChildService(ctx, payload) {
    return getServicesChilds(payload);
    // .then((res) => {
    //   ctx.commit("setChildServices", res.data.category.priority_services);
    // })
  },

  getGransonService(ctx, payload) {
    return getGransonServices(payload);
    // .then((res) => {
    //   console.log(res.data);
    //   ctx.commit("setGrandsons", res.data.collection);
    // })
    // .catch((error) => {
    //   console.log(error);
    // });
  },
  remove(ctx, payload) {
    return removeSpecialty(payload);
  },
  detail(ctx, payload) {
    getSpecialtyDetail(payload).then((res) => ctx.commit('setDetail', res.data));
  },
  update(ctx, payload) {
    return updateSpecialty(payload);
  },
  statusUpdate(ctx, payload) {
    if (payload.user_id && payload.specialityId) return StatusAllSpecialty(payload);
    else if (payload.user_id) UserStatusAllSpecialty(payload);
    else return StatusOneSpecialty(payload);
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
