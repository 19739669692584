<script setup>
import { useRouter } from 'vue-router';
import { isAuthentificated } from '@/composables/auth';
import store from '../../store/index.js';
import { ref } from 'vue';
import useSettings from '@/composables/settings';

const { settings } = useSettings();
const router = useRouter();
const currentDate = ref(new Date());

const logoUrl = () => {
  // console.log(store.state.profileStore.type);
  if (isAuthentificated.value && store.state.profileStore.type == 'executor') {
    router.push('/account');
  } else {
    router.push('/');
  }
};
</script>

<template>
  <footer class="footer-main bg-gray-700 py-6 flex w-full items-center justify-center">
    <div class="container px-4 2xl:px-20 mx-auto">
      <div
        class="footer-top flex flex-col md:flex-row items-center text-center md:text-left md:justify-between border-b border-2-gray-200 pb-6 mb-5"
      >
        <h3 class="text-gray-200 text-xl font-semibold mb-2">
          {{ $t('footer.download_app') }}
        </h3>
        <div class="flex first:mr-5">
          <a href="{{ this.theme.Android }}" class="mr-2">
            <img class="" src="@/assets/images/app.png" alt="" />
          </a>
          <a href="{{ this.theme.Ios }}" class="">
            <img class="" src="@/assets/images/play.png" alt="" />
          </a>
        </div>
      </div>
      <div
        class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 text-sm md:text-lg font-medium md:text-left text-gray-200 pb-5"
      >
        <div class="footer-menu">
          <h5 class="text-gray-400 text-base xl:text-lg">
            {{ $t('footer.menu.company') }}
          </h5>
          <ul>
            <li class="my-3 text-xs sm:text-sm md:text-md lg:text-base">
              <router-link to="/about" class="hover:text-blue-300">
                {{ $t('footer.menu.about_us') }}
              </router-link>
            </li>
            <!-- <li
                            class="
                                my-3
                                text-xs
                                sm:text-sm
                                md:text-md
                                lg:text-base
                            "
                        >
                            <a class="hover:text-blue-300" href="#">
                                Вакансии
                            </a>
                        </li> -->
            <li class="my-3 text-xs sm:text-sm md:text-md lg:text-base">
              <router-link to="/blog" class="hover:text-blue-300">
                {{ $t('footer.menu.blog') }}
              </router-link>
            </li>
            <li class="my-3 text-xs sm:text-sm md:text-md lg:text-base">
              <router-link to="/contact" class="hover:text-blue-300">
                {{ $t('footer.menu.contacts') }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="footer-menu">
          <h5 class="text-gray-400 text-base xl:text-lg">
            {{ $t('footer.menu.specialists') }}
          </h5>
          <ul>
            <li class="my-3 text-xs sm:text-sm md:text-md lg:text-base">
              <router-link to="/signup" class="hover:text-blue-300">
                {{ $t('footer.menu.become_specialist') }}
              </router-link>
            </li>
            <li class="my-3 text-xs sm:text-sm md:text-md lg:text-base">
              <router-link to="/services-all" class="hover:text-blue-300">
                {{ $t('footer.menu.all_services') }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="footer-menu">
          <h5 class="text-gray-400 text-base xl:text-lg">
            {{ $t('footer.menu.support') }}
          </h5>
          <ul>
            <li class="my-3 text-xs sm:text-sm md:text-md lg:text-base">
              <router-link to="/faq" class="hover:text-blue-300">
                {{ $t('footer.menu.faq') }}
              </router-link>
            </li>
            <li class="my-3 text-xs sm:text-sm md:text-md lg:text-base">
              <router-link to="/legal" class="hover:text-blue-300">
                {{ $t('footer.menu.legal') }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="footer-socials">
          <h5 class="text-gray-400 text-base xl:text-lg">
            {{ $t('footer.menu.social_network') }}
          </h5>
          <div class="my-2">
            <ul v-if="settings?.social_networks" class="footer-socials__ul flex items-center">
              <li v-for="(item, index) in settings?.social_networks" :key="index" class="mr-5">
                <a target="_blank" :href="item.url">
                  <img
                    class="transition-all duration-500 transform hover:scale-110 white"
                    :src="item.image"
                    alt=""
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="footer-bottom md:grid block items-center text-center md:text-left font-medium text-sm md:text-lg md:grid-cols-4 text-gray-200 border-t border-slate-300"
      >
        <div class="my-2">
          <ul>
            <li class="text-xs sm:text-sm md:text-md lg:text-lg">
              <button @click="logoUrl">
                <img src="@/assets/logo-white.svg" alt="" class="w-44 mx-auto md:mx-0" />
              </button>
            </li>
          </ul>
        </div>
        <div class="my-2">
          <ul>
            <li class="text-xs sm:text-sm md:text-md lg:text-lg" />
          </ul>
        </div>
        <div class="my-2">
          <ul>
            <li class="text-xs sm:text-sm md:text-md lg:text-lg">
              <span>© {{ currentDate.getFullYear() }}. {{ $t('footer.copyright') }} </span>
            </li>
          </ul>
        </div>
        <!-- <div class="my-2">
                    <ul>
                        <li class="text-xs sm:text-sm md:text-md lg:text-lg">
                            <span
                                >Разработка сайта:<a
                                    class="hover:text-blue-300"
                                    href="#"
                                >
                                    BigSoft</a
                                ></span
                            >
                        </li>
                    </ul>
                </div> -->
      </div>
    </div>
  </footer>
</template>
