<template>
  <svg
    class="w-6 h-6 xl:m-auto mr-3 fill-current"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M21.9215 18.8246C21.6379 17.4625 21.0965 16.1691 20.3402 15.009C20.323 14.9789 20.3102 14.9488 20.2887 14.923C20.0266 14.532 19.7387 14.1582 19.4336 13.8059C19.0211 13.3246 18.5656 12.882 18.0715 12.4738H18.0586C16.9973 11.6102 15.7941 10.9484 14.4965 10.523C15.9188 9.45312 16.8426 7.75586 16.8426 5.84375C16.8426 2.62109 14.2215 0 10.9988 0C7.77618 0 5.15508 2.62109 5.15508 5.84375C5.15508 7.75586 6.07891 9.45742 7.50118 10.523C6.19493 10.9527 4.97032 11.623 3.9004 12.5039C1.93672 14.1195 0.570319 16.3754 0.0546936 18.8504C-0.108588 19.6281 0.0847717 20.423 0.587506 21.0375C1.08594 21.6477 1.82071 22 2.60704 22L15.9531 21.9699H19.3691C20.1555 21.9699 20.8945 21.6176 21.3887 21.0074C21.8871 20.3973 22.0805 19.598 21.9215 18.8246ZM6.87383 5.84375C6.87383 3.5707 8.72579 1.71875 10.9988 1.71875C13.2719 1.71875 15.1238 3.5707 15.1238 5.84375C15.1238 8.04805 13.3836 9.85273 11.2094 9.96445C11.1406 9.96445 11.0719 9.96445 10.9988 9.96445C10.9301 9.96445 10.8613 9.96445 10.7926 9.96445C8.61407 9.85273 6.87383 8.04805 6.87383 5.84375ZM20.0266 19.9547C19.9277 20.075 19.7043 20.2812 19.3391 20.2812H15.0938H2.60704C2.2418 20.2812 2.01837 20.075 1.91954 19.9547C1.74766 19.7441 1.68321 19.4691 1.73907 19.2027C2.63282 14.9187 6.40977 11.7906 10.7711 11.6832C10.8484 11.6875 10.9215 11.6875 10.9988 11.6875C11.059 11.6875 11.1234 11.6875 11.1836 11.6832L12.8508 11.8723C12.3137 11.7605 11.7594 11.6961 11.1965 11.6789C11.2094 11.6789 11.2223 11.6789 11.2309 11.6789C11.8281 11.6918 12.4125 11.7648 12.9883 11.8852L12.8508 11.868C16.4773 12.6199 19.4379 15.4688 20.2113 19.1941C20.2672 19.4691 20.1984 19.7441 20.0266 19.9547Z"
      />
    </g>
  </svg>
</template>

<script setup></script>

<style></style>
