<template>
  <li>
    <slot />
  </li>
</template>

<script>
export default {};
</script>

<style></style>
