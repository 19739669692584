<script setup>
import { computed, onMounted, ref } from 'vue';
import CLogin from '@/components/auth/c-login';
import cRegister from '@/components/auth/c-register';
import { isAuthentificated } from '@/composables/auth';
import cUserMenu from '@/components/userMenu/c-user-menu';
import cLanguage from '@/components/userMenu/c-language';
import cNotification from '@/components/userMenu/c-notification';
import cShare from '@/components/userMenu/c-share';
import { useRoute, useRouter } from 'vue-router';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import store from '../../store/index.js';
import useMarqueeList from '@/composables/marquee';

// Import Swiper styles
import 'swiper/css';

const showLoginModal = ref(false);
const showRegisterModal = ref(false);
const route = useRoute();
const router = useRouter();
const headerType = computed(() => {
  return route.meta?.header || 'secondary';
});
const navOpen = ref(false);
const { marquee, isLoadedMarquee, isLoadingMarquee, exec } = useMarqueeList();

onMounted(async() => {
  document.addEventListener('keyup', function (evt) {
    if (evt.keyCode === 27) {
      showLoginModal.value = false;
      showRegisterModal.value = false;
      navOpen.value = false;
    }
  });
  if (!isLoadingMarquee.value) await exec();
  if (route.query?.modal == 'login') {
    showLoginModal.value = true
    navOpen.value = !navOpen.value
    router.replace({
      modal: null
    })
  }
  if (route.query?.modal == 'registration') {
    showRegisterModal.value = true
    navOpen.value = !navOpen.value
    router.replace({
      modal: null
    })
  }
});


const logoUrl = () => {
  // console.log(isAuthentificated.value);
  // console.log(store.state.profileStore.type);
  if (isAuthentificated.value && store?.state?.profileStore?.type == 'executor') {
    router.push('/account');
  } else {
    router.push({ path: '/' });
  }
};
function closeMenu(){
  navOpen.value = false;
}
</script>
<template>
  <header
    class="header-main top-0 z-50 shadow-xl text-white mt-[-6px]"
    :class="{
      'bg-primary': headerType == 'secondary',
      'bg-white': headerType == 'primary'
    }"
  >
    <swiper
      v-if="isLoadedMarquee.value"
      :slides-per-view="1"
      :space-between="50"
      :speed="20000"
      :loop="true"
      :allow-touch-move="false"
      :autoplay="{
        delay: 0,
        disableOnInteraction: false
      }"
      :modules="[Autoplay]"
      class="bg-orange-400 py-1"
    >
      <swiper-slide v-for="(item, index) in marquee" :key="index">
        {{ item.title }}
      </swiper-slide>
    </swiper>
    <div
      class="container px-4 2xl:px-20 mx-auto flex justify-between items-stretchx xl:py-1 lg:h-[80px]"
    >
      <div class="flex py-2 xl:py-0 justify-between w-full xl:w-auto items-center">
        <button class="py-2 mr-12 my-auto w-36 md:w-40" @click="logoUrl">
          <img :src="require(`@/assets/logo${headerType == 'secondary' ? '-white' : ''}.svg`)" />
        </button>
        <div class="block xl:hidden flex">
          <div v-if="isAuthentificated" class="mobile-noti mr-4">
            <c-notification />
          </div>
          <button
            class="flex text-black items-center text-teal-lighter border-teal-light ml-auto"
            @click="navOpen = !navOpen"
          >
            <h-menu
              v-if="!navOpen"
              class="fill-current h-7 w-7"
              :class="{
                'text-black': headerType != 'secondary',
                'text-white': headerType == 'secondary'
              }"
            />
            <h-x
              v-else
              class="fill-current h-7 w-7"
              :class="{
                'text-black': headerType != 'secondary',
                'text-white': headerType == 'secondary'
              }"
            />
          </button>
        </div>
      </div>
      <nav
        class="menu-nav font-medium justify-between items-stretch block xl:flex bg-primary xl:bg-transparent absolute xl:static w-10/12 inset-x-0 h-screen xl:h-auto z-10 transition-all duration-300 ease-in-out"
        :class="{ '-left-full': !navOpen }"
      >
        <router-link to="/" class="inline-block mt-4 mx-3 xl:hidden h-8 z-40">
          <img class="w-full h-full object-contain" src="@/assets/logo-white.svg" />
        </router-link>
        <ul
          v-if="isAuthentificated"
          class="block mt-0 md:mt-4 xl:mt-0 xl:flex items-center px-3 xl:px-0 xl:pt-0 pt-3 gap-4 z-30"
        >
          <li>
            <router-link
              to="/orders"
              class="no-underline xl:block flex mt-0 xl:py-0 xl:border-0 border-b sm:mt-0 xl:text-lg sm:text-base text-base xl:mr-4 py-3 border-gray-50/25"
              :class="{
                '': headerType == 'secondary',
                'xl:text-primary text-white': headerType == 'primary'
              }"
              @click="navOpen = !navOpen"
            >
              <h-order />
              <span
                :class="{
                  '': headerType == 'secondary',
                  'xl:text-black text-white': headerType == 'primary'
                }"
              >
                {{ $t('menu.Orders') }}
              </span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/wallet"
              class="no-underline xl:block flex mt-0 xl:py-0 xl:border-0 border-b text-white sm:mt-0 xl:text-lg sm:text-base text-base xl:mr-4 py-3 border-gray-50/25"
              :class="{
                '': headerType == 'secondary',
                'xl:text-primary text-white': headerType == 'primary'
              }"
              @click="navOpen = !navOpen"
            >
              <h-wallet />
              <span
                :class="{
                  '': headerType == 'secondary',
                  'xl:text-black text-white': headerType == 'primary'
                }"
              >
                {{ $t('menu.Wallet') }}
              </span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/account"
              class="no-underline xl:block flex mt-0 xl:py-0 xl:border-0 border-b text-white sm:mt-0 xl:text-lg sm:text-base text-base xl:mr-4 py-3 border-gray-50/25"
              :class="{
                '': headerType == 'secondary',
                'xl:text-primary text-white': headerType == 'primary'
              }"
              @click="navOpen = !navOpen"
            >
              <h-account />
              <span
                :class="{
                  '': headerType == 'secondary',
                  'xl:text-black text-white': headerType == 'primary'
                }"
              >
                {{ $t('menu.Profile') }}
              </span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/support"
              class="no-underline xl:block flex mt-0 xl:py-0 xl:border-0 border-b text-white sm:mt-0 xl:text-lg sm:text-base text-base xl:mr-4 py-3 border-gray-50/25"
              :class="{
                '': headerType == 'secondary',
                'xl:text-primary text-white': headerType == 'primary'
              }"
              @click="navOpen = !navOpen"
            >
              <h-support />
              <span
                :class="{
                  '': headerType == 'secondary',
                  'xl:text-black text-white': headerType == 'primary'
                }"
              >
                {{ $t('menu.Support') }}
              </span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/bonus"
              class="no-underline xl:block flex mt-0 xl:py-0 xl:border-0 border-b text-white sm:mt-0 xl:text-lg sm:text-base text-base xl:mr-4 py-3 border-gray-50/25"
              :class="{
                '': headerType == 'secondary',
                'xl:text-primary text-white': headerType == 'primary'
              }"
              @click="navOpen = !navOpen"
            >
              <h-bonus />
              <span
                :class="{
                  '': headerType == 'secondary',
                  'xl:text-black text-white': headerType == 'primary'
                }"
              >
                {{ $t('menu.Bonuses') }}
              </span>
            </router-link>
          </li>
        </ul>
        <ul
          v-if="isAuthentificated"
          class="block xl:mt-0 xl:flex items-center px-3 xl:px-0 xl:pb-0 pb-3 user-menu"
          :class="store?.state?.profileStore?.type == 'executor' ? 'gap-4' : 'gap-1'"
        >
          <li class="hidden xl:block">
            <c-notification />
          </li>
          <li>
            <c-user-menu @closeMenu="closeMenu" />
          </li>
          <li>
            <c-share />
          </li>
          <li>
            <c-language />
          </li>
        </ul>
        <ul
          v-else
          class="block mt-0 md:mt-4 xl:mt-0 xl:flex items-center px-3 xl:ml-auto xl:px-0 xl:pt-0 pt-3 gap-4"
        >
          <!--<li>
                         <button
                    type="button"
                    class="
                        flex
                        items-center
                        border-2 border-white
                        rounded-full
                        px-5
                        py-2
                        hover:bg-white hover:text-primary
                        transition
                        duration-200
                    "
                >
                    <h-location class="mr-2"></h-location>
                    г. Ташкент
                </button>                    </li> -->
          <li>
            <button
              type="button"
              class="flex items-center xl:border border-b w-full xl:w-auto xl:rounded-full border-gray-50/25 text-white xl:px-5 px-0 xl:py-2 py-3 transition-all duration-300 ease-out hover:ease-in xl:text-base xl:text-lg sm:text-base text-base outline-0"
              :class="{
                'xl:border-white xl:hover:bg-white xl:hover:text-primary':
                  headerType == 'secondary',
                'xl:border-primary xl:hover:bg-primary xl:hover:text-white xl:text-black':
                  headerType == 'primary'
              }"
              @click="(showLoginModal = true), (navOpen = !navOpen)"
            >
              <h-enter class="mr-2 xl:block hidden" />
              {{ $t('profile.login') }}
            </button>
          </li>
          <li>
            <button
              type="button"
              class="flex items-center xl:border border-b w-full xl:w-auto xl:rounded-full border-gray-50/25 text-white xl:px-5 px-0 xl:py-2 py-3 transition-all duration-300 ease-out hover:ease-in xl:text-base xl:text-lg sm:text-base text-base outline-0"
              :class="{
                'xl:border-white xl:hover:bg-white xl:hover:text-primary':
                  headerType == 'secondary',
                'xl:border-primary xl:hover:bg-primary xl:hover:text-white xl:text-black':
                  headerType == 'primary'
              }"
              @click="(showRegisterModal = true), (navOpen = !navOpen)"
            >
              <h-pencil class="mr-2 xl:block hidden" />
              {{ $t('profile.signup') }}
            </button>
          </li>
          <li
            class="xl:hidden flex items-center xl:border border-b w-full xl:w-auto xl:rounded-full border-gray-50/25 text-white xl:px-5 px-0 xl:py-2 py-3 transition-all duration-300 ease-out hover:ease-in xl:text-base xl:text-lg sm:text-base text-base outline-0"
            @click="navOpen = !navOpen"
          >
            <router-link to="/about" class="w-full h-full">
              {{ $t('footer.menu.about_us') }}
            </router-link>
          </li>
          <li
            class="xl:hidden flex items-center xl:border border-b w-full xl:w-auto xl:rounded-full border-gray-50/25 text-white xl:px-5 px-0 xl:py-2 py-3 transition-all duration-300 ease-out hover:ease-in xl:text-base xl:text-lg sm:text-base text-base outline-0"
            @click="navOpen = !navOpen"
          >
            <router-link to="/faq" class="w-full h-full">
              {{ $t('footer.menu.faq') }}
            </router-link>
          </li>
          <li
            class="xl:hidden flex items-center xl:border border-b w-full xl:w-auto xl:rounded-full border-gray-50/25 text-white xl:px-5 px-0 xl:py-2 py-3 transition-all duration-300 ease-out hover:ease-in xl:text-base xl:text-lg sm:text-base text-base outline-0"
            @click="navOpen = !navOpen"
          >
            <router-link to="/legal" class="w-full h-full">
              {{ $t('footer.menu.legal') }}
            </router-link>
          </li>
          <li>
            <c-language />
          </li>
        </ul>
        <!-- <h3 class="text-center">Бесплатное мобильное приложение</h3> -->
        <!-- <div class="xl:hidden flex items-center w-full justify-center py-3 mt-4">
          <a class="mx-auto" href="#">
            <img class="w-32" src="@/assets/images/app.png" alt="" />
          </a>
          <a class="mx-auto" href="#">
            <img class="w-32" src="@/assets/images/play.png" alt="" />
          </a>
        </div> -->
      </nav>
    </div>
  </header>
  <c-modal v-if="!isAuthentificated" v-slot="{ close }" v-model="showLoginModal">
    <button
      class="absolute top-3 right-3 bg-gray-300 hover:bg-primary hover:text-white transition duration-200 rounded-full p-2"
      @click="close"
    >
      <h-x class="w-3 h-3" />
    </button>
    <c-login :is-show-modal="showLoginModal" @successful="close" />
  </c-modal>
  <c-modal v-if="!isAuthentificated" v-slot="{ close }" v-model="showRegisterModal">
    <button
      class="absolute top-3 right-3 bg-gray-300 hover:bg-primary hover:text-white transition duration-200 rounded-full p-2"
      @click="close"
    >
      <h-x class="w-3 h-3" />
    </button>
    <c-register :is-show-modal="showRegisterModal" />
  </c-modal>
</template>
<style>
header .swiper-wrapper {
  @apply !ease-linear;
  white-space: nowrap;
}
</style>
