<script setup>
import CCategories from '@/components/home/c-categories';
import HomeHeader from '@/components/home/home-header.vue';
import CAdvantages from '@/components/home/c-advantages.vue';
import CQuestions from '@/components/home/c-questions.vue';
//import CApplication from '@/components/home/c-application.vue';
import { ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
const route = useRoute();
const category = ref();
onMounted(() => {
  if (route.query.order) {
    category.value.scrollIntoView();
  }
});
</script>
<template>
  <div>
    <home-header />
    <div ref="category">
      <c-categories />
    </div>
    <c-advantages />
    <c-questions />
   <!-- <c-application />-->
  </div>
</template>
