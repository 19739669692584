import { sendSms, checkSms, phoneCheck } from '@/api/auth';
import { readonly, ref } from 'vue';
const isSending = ref(false);
const isSended = ref(false);
const result = ref(null);
export default function useSmsApi() {
  const onCheckPhone = async (phone) => {
    isSending.value = true;
    await phoneCheck(phone)
      .then((response) => response?.data)
      .then((data) => {
        result.value = data;
        isSended.value = true;
      })
      .finally(() => (isSending.value = false));
  };
  const onSend = async (formData, send) => {
    isSending.value = true;
    if (send)
      await sendSms(formData)
        .then((response) => response?.data)
        .then((data) => {
          result.value = data.data;
          isSended.value = true;
        })
        .finally(() => (isSending.value = false));
    else
      await checkSms(formData)
        .then((response) => response?.data)
        .then((data) => {
          result.value = data;
          isSended.value = true;
        })
        .finally(() => (isSending.value = false));
  };
  return {
    onSend,
    onCheckPhone,
    isSending: readonly(isSending),
    isSended: readonly(isSended),
    result: readonly(result)
  };
}
