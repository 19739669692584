<script setup>
import useSettings from '@/composables/settings';

const { settings } = useSettings();
</script>

<template>
  <div class="bg-white">
    <div class="mx-auto container px-4 md:py-12 sm:py-8 py-4 2xl:px-20 pb-0">
      <h2
        class="lg:text-3xl sm:text-2xl text-xl font-medium md:mb-5 mb-0 text-center md:text-left"
        v-html="$t('home.advantages')"
      />
    </div>
    <div
      class="container mx-auto grid gap-2 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 p-4 2xl:px-20 pb-10"
    >
      <!-- TODO: после подключения API вставить на key -> id приемущества -->
      <div
        v-for="(advantage, index) in settings?.advantages"
        :key="index"
        class="flex p-2 shadow-primary hover:shadow-lg transition duration-200 rounded-xl cursor-pointer group"
      >
        <div class="advantages-item py-1 relative">
          <!-- <div class="w-20 h-20 flex justify-center items-center rounded-full">
                        <img src="@/assets/logo.svg" alt="" class="" />
                    </div>
                    <div
                        class="
                            absolute
                            inset-0
                            rounded-full
                            bg-orange-400
                            text-4xl
                            w-20
                            h-20
                            flex
                            justify-center
                            items-center
                            text-white
                            opacity-0
                            group-hover:opacity-50
                        "
                    >
                        <div class="align-top">0{{ index + 1 }}</div>
                    </div> -->
          <div
            class="rounded-full bg-orange-400 text-4xl w-20 h-20 flex justify-center items-center text-white"
          >
            <div class="advantages-item__count align-top">0{{ index + 1 }}</div>
          </div>
        </div>
        <div class="px-4">
          <h4 class="advantages-item__title lg:text-2xl text-xl mb-2">
            {{ advantage.title }}
          </h4>
          <div class="text-sm text-gray-500" v-html="advantage.description" />
        </div>
      </div>
    </div>
  </div>
</template>
