<template>
  <div class="bg-white rounded-xl px-4 py-4 flex flex-col" :class="{ 'animate-pulse': isLoading }">
    <div class="flex items-center mb-4">
      <div class="p-3 rounded-full bg-gray-100">
        <img :src="iconPath" class="h-9 w-9" alt="" />
      </div>

      <div class="ml-2">
        <h3 class="md:text-xl font-medium text-xl">
          <router-link :to="`/services-all?category=${slug}`">
            {{ name }}
          </router-link>
          <!-- <span class="sm:text-base text-sm text-gray-300"> (2)</span> -->
        </h3>
      </div>
    </div>
    <div class="mt-2">
      <ul class="mb-4">
        <li v-for="service in services" :key="service.id" class="mb-2">
          <!-- `/${slug}/${service.slug}/select` -->
          <button class="flex items-center text-left" @click="NextStep(slug, service)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-gray-400 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            {{ service.name }}
          </button>
        </li>
      </ul>
    </div>

    <div class="border-t border-dashed pt-3 border-gray-400 mt-auto">
      <!-- :to="`/${slug}/${services[0].slug}/`" -->
      <router-link :to="`/services-all?category=${slug}`" class="text-blue-900 flex items-center">
        {{ $t('services_all_btn', { msg: count }) }}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 text-blue-900 ml-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M17 8l4 4m0 0l-4 4m4-4H3"
          />
        </svg>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import useServiceApi from '@/composables/services/instance';
import { OrderDataSession } from '@/composables/orders/instance&storage';
import { useRouter } from 'vue-router';

defineProps({
  name: {
    type: String,
    required: true
  },
  iconPath: {
    type: String
  },
  slug: {
    type: String,
    required: true
  },
  services: {
    type: Array,
    default() {
      return [];
    }
  },
  count: {
    type: Number,
    default: 0
  }
});
const router = useRouter();
const { service: fetchedService, onGet: onGetService, isLoading } = useServiceApi();
const { SaveSession } = OrderDataSession();
const NextStep = async (slug, service) => {
  await onGetService(service.slug, { count: ['children'] });
  SaveSession({
    parent_service_id: service.id,
    // services: [{ slug: service.slug, name: service.name }],
    services: [service.slug],
    category: slug
  });
  router.push({
    name: 'order.update',
    params: {
      categorySlug: slug,
      serviceSlugs: [service.slug],
      form: fetchedService.value.children_count > 0 ? 'select' : 'work-place'
    }
  });
};
</script>
