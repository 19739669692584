<template>
  <svg
    class="w-6 h-6 xl:m-auto mr-3 fill-current"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M21.635 4.24984C21.5057 2.58035 20.1069 1.26123 18.4048 1.26123H3.24103C1.45392 1.26123 0 2.71515 0 4.50226V19.4978C0 21.2849 1.45392 22.7388 3.24103 22.7388H20.759C22.5461 22.7388 24 21.2849 24 19.4978V7.36956C24 5.88606 22.9977 4.63309 21.635 4.24984ZM3.24103 2.77529H18.4049C19.2288 2.77529 19.9193 3.35541 20.0907 4.12857H3.24103C2.6065 4.12857 2.01445 4.31258 1.51406 4.62902V4.50226C1.51406 3.55001 2.28878 2.77529 3.24103 2.77529ZM20.759 21.2248H3.24103C2.28878 21.2248 1.51406 20.45 1.51406 19.4978V7.36956C1.51406 6.41731 2.28878 5.64258 3.24103 5.64258H20.759C21.7113 5.64258 22.486 6.41731 22.486 7.36956V10.0115H17.6587C15.7458 10.0115 14.1895 11.5678 14.1895 13.4807C14.1895 15.3936 15.7458 16.95 17.6587 16.95H22.4859V19.4978C22.4859 20.45 21.7112 21.2248 20.759 21.2248ZM22.4859 15.4359H17.6587C16.5806 15.4359 15.7036 14.5588 15.7036 13.4807C15.7036 12.4026 16.5806 11.5256 17.6587 11.5256H22.4859V15.4359Z"
      />
      <path
        d="M17.9395 14.3263C18.3748 14.3263 18.7277 13.9734 18.7277 13.5382C18.7277 13.1029 18.3748 12.75 17.9395 12.75C17.5042 12.75 17.1514 13.1029 17.1514 13.5382C17.1514 13.9734 17.5042 14.3263 17.9395 14.3263Z"
      />
    </g>
  </svg>
</template>

<script setup></script>

<style></style>
