<template>
  <svg
    class="w-6 h-6 xl:m-auto mr-3 fill-current"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5625 6.4547H17.0142C17.3406 6.23189 17.6209 6.01051 17.8164 5.81214C18.975 4.64632 18.975 2.74882 17.8164 1.58301C16.6908 0.450262 14.7272 0.448824 13.6002 1.58301C12.9778 2.20832 11.3232 4.75414 11.5518 6.4547H11.4483C11.6754 4.75414 10.0223 2.20832 9.39981 1.58301C8.27281 0.448824 6.30919 0.450262 5.18362 1.58301C4.025 2.74882 4.025 4.64632 5.18219 5.81214C5.37913 6.01051 5.65944 6.23189 5.98575 6.4547H1.4375C0.645437 6.4547 0 7.10014 0 7.8922V11.4859C0 11.8827 0.322 12.2047 0.71875 12.2047H1.4375V20.8297C1.4375 21.6218 2.08294 22.2672 2.875 22.2672H20.125C20.9171 22.2672 21.5625 21.6218 21.5625 20.8297V12.2047H22.2812C22.678 12.2047 23 11.8827 23 11.4859V7.8922C23 7.10014 22.3546 6.4547 21.5625 6.4547ZM6.20281 2.59645C6.49463 2.3032 6.88131 2.1422 7.29244 2.1422C7.70213 2.1422 8.08881 2.3032 8.38062 2.59645C9.3495 3.57107 10.3126 6.06082 9.97769 6.40726C9.97769 6.40726 9.91731 6.4547 9.71175 6.4547C8.71844 6.4547 6.82238 5.42257 6.20281 4.7987C5.6005 4.19207 5.6005 3.20307 6.20281 2.59645ZM10.7812 20.8297H2.875V12.2047H10.7812V20.8297ZM10.7812 10.7672H1.4375V7.8922H9.71175H10.7812V10.7672ZM14.6194 2.59645C15.203 2.01139 16.215 2.01282 16.7972 2.59645C17.3995 3.20307 17.3995 4.19207 16.7972 4.7987C16.1776 5.42257 14.2816 6.4547 13.2883 6.4547C13.0827 6.4547 13.0223 6.4087 13.0209 6.40726C12.6874 6.06082 13.6505 3.57107 14.6194 2.59645ZM20.125 20.8297H12.2188V12.2047H20.125V20.8297ZM21.5625 10.7672H12.2188V7.8922H13.2883H21.5625V10.7672Z"
    />
  </svg>
</template>
