<template>
  <form class="block max-w-[24rem] md:w-96" @submit.prevent="onRegisterHandler">
    <h3 class="block text-xl md:text-xl text-center mb-6">
      {{ $t('profile.signup') }} <span v-if="step == 3"> {{ $t('profile.and_login') }}</span>
    </h3>
    <p v-if="registerErrorMessage" class="mb-6 text-red-500 font-thin text-sm">
      {{ registerErrorMessage }}
    </p>
    <p v-if="registerExternalResults.smsCode[0]" class="mb-6 text-red-500 font-thin text-sm">
      {{ registerExternalResults.smsCode[0] }}
    </p>
    <p v-if="step == 2" class="mb-5 text-primary text-sm">
      {{
        $t('profile.sms_code_title', { name: registerState.name, username: registerState.username })
      }}
    </p>
    <div class="flex flex-col">
      <div v-show="step === 1">
        <c-input
          v-model="registerState.name"
          name="name"
          class="w-full"
          type="text"
          :valid="!registerValidator.name.$error"
        >
          {{ $t('profile.name') }}
        </c-input>
        <div class="mt-0.5 mb-3.5">
          <div
            v-for="error in registerValidator.name.$errors"
            :key="error.$uid"
            class="text-red-500"
          >
            {{ error.$message }}
          </div>
        </div>
        <!------------------ Фамилия------------ -->
        <c-input
          v-model="registerState.surname"
          name="surname"
          class="w-full"
          type="text"
          :valid="!registerValidator.surname.$error"
        >
          {{ $t('profile.surname') }}
        </c-input>
        <div class="mt-0.5 mb-3.5">
          <div
            v-for="error in registerValidator.surname.$errors"
            :key="error.$uid"
            class="text-red-500"
          >
            {{ error.$message }}
          </div>
        </div>
        <c-input
          v-model.trim="registerState.username"
          v-maska="['+998 ## ###-##-##', '+998 ## ###-##-##']"
          name="username"
          class="w-full"
          type="text"
          :valid="!registerValidator.username.$error"
        >
          {{ $t('profile.phone_number') }}
        </c-input>
        <div class="mt-0.5 mb-3.5">
          <div
            v-for="error in registerValidator.username.$errors"
            :key="error.$uid"
            class="text-red-500"
          >
            {{ error.$message }}
          </div>
        </div>
      </div>
      <!------------------ Отчество------------ -->
      <!-- <c-input
				v-model="registerState.patronymic"
				name="patronymic"
				class="w-full"
				type="text"
				:valid="!registerValidator.patronymic.$error"
			>
				{{$t('profile.patronymic')}}
			</c-input>
			<div class="mt-0.5 mb-3.5">
				<div
					class="text-red-500"
					v-for="error in registerValidator.patronymic.$errors"
					:key="error.$uid"
				>
					{{ error.$message }}
				</div>
			</div> -->
      <!------------- Электронный почтовый адрес -------------->
      <!-- <c-input
				v-model="registerState.email"
				name="email"
				class="w-full"
				type="email"
				:valid="!registerValidator.email.$error"
			>
				{{$t('profile.email')}}
			</c-input>
			<div class="mt-0.5 mb-3.5">
				<div
					class="text-red-500"
					v-for="error in registerValidator.email.$errors"
					:key="error.$uid"
				>
					{{ error.$message }}
				</div>
			</div> -->
      <div v-show="step === 2">
        <c-input
          v-model="registerState.smsCode"
          v-maska="['####', '####']"
          name="smsCode"
          class="w-full"
          type="text"
          eye
          :valid="!registerValidator.smsCode.$error"
        >
          {{ $t('profile.sms_code') }}
        </c-input>
        <div class="mt-0.5 mb-2">
          <div
            v-for="error in registerValidator.smsCode.$errors"
            :key="error.$uid"
            class="text-red-500"
          >
            {{ error.$message }}
          </div>
        </div>
        <p v-if="countDown > 0" class="mb-3 text-primary text-sm float-right">
          {{ `00:${countDown}` }}
        </p>
        <button
          v-else
          class="mb-3 text-primary text-sm float-right underline"
          type="button"
          @click="Resend()"
        >
          {{ $t('profile.resend') }}
        </button>
      </div>
      <div v-show="step === 3">
        <c-input
          v-model="registerState.password"
          name="password"
          class="w-full"
          type="password"
          eye
          :valid="!registerValidator.password.$error"
        >
          {{ $t('profile.password') }}
        </c-input>
        <div class="mt-0.5 mb-3.5">
          <div
            v-for="error in registerValidator.password.$errors"
            :key="error.$uid"
            class="text-red-500"
          >
            {{ error.$message }}
          </div>
        </div>
        <c-input
          v-model="registerState.passwordConfirmation"
          name="passwordConfirmation"
          class="w-full"
          type="password"
          eye
          :valid="!registerValidator.passwordConfirmation.$error"
        >
          {{ $t('profile.password_confirmation') }}
        </c-input>
        <div class="mt-0.5 mb-3.5">
          <div
            v-for="error in registerValidator.passwordConfirmation.$errors"
            :key="error.$uid"
            class="text-red-500"
          >
            {{ error.$message }}
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-4">
      <c-button value="customer" :disabled="isRegistering" :loading="isRegistering">
        <span v-if="step == 3">{{ $t('profile.reg_customer') }}</span>
        <span v-else>{{ step == 2 ? $t('profile.confirm') : $t('profile.reg_btn') }}</span>
        <!-- как исполнитель -->
      </c-button>
      <c-button
        v-if="step == 3"
        scheme="secondary"
        value="executor"
        :disabled="isRegistering"
        :loading="isRegistering"
      >
        {{ $t('profile.reg_executor') }}
      </c-button>
    </div>
  </form>
</template>

<script setup>
import { useRegister, useSmsApi } from '@/composables/auth';
import { ProfileType } from '@/utils/enums';
import { useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import {
  // email,
  helpers,
  required,
  // requiredIf,
  // alphaNum,
  maxLength,
  minLength,
  sameAs
} from '@vuelidate/validators';
import { or } from '@vueuse/core';
import { reactive, ref, toRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const router = useRouter();
const emit = defineEmits(['successful']);
const props = defineProps({
  isShowModal: {
    type: Boolean,
    required: true
  }
});
const { onRegister, isRegistering, profile } = useRegister();
const { onSend, onCheckPhone, result } = useSmsApi();
const step = ref(1);
const countDown = ref(59);
const required_id = ref(null);
const registerState = reactive({
  name: '',
  surname: '',
  // patronymic: "",
  username: '',
  // email: "",
  password: '',
  smsCode: '',
  passwordConfirmation: '',
  type: ProfileType.customer
});
watch(
  () => props.isShowModal,
  (val) => {
    // console.log(val);
    if (!val) {
      setTimeout(() => {
        (step.value = 1),
          (registerState.username = ''),
          (registerState.password = ''),
          (registerState.type = ProfileType.customer),
          (registerState.smsCode = ''),
          (registerState.name = ''),
          (registerState.surname = ''),
          (registerState.passwordConfirmation = '');
        registerValidator.value.$reset();
      }, 500);
    }
  }
);
const countDownTimer = () => {
  if (countDown.value > 0) {
    setTimeout(() => {
      countDown.value -= 1;
      countDownTimer();
    }, 1000);
  }
};
const Resend = async () => {
  var bodyFormData = new FormData();
  var send = true;
  var phone = registerState.username.replace(/\s/g, '').toLowerCase().split('-');
  phone = phone[0].slice(4, 14) + phone[1] + phone[2];
  bodyFormData.append('phone', phone);
  bodyFormData.append('type', "reg");
  await onSend(bodyFormData, send);
  required_id.value = result.value.required_id;
  countDown.value = 59;
  countDownTimer();
};
watch(
  () => registerState.name,
  () => {
    registerExternalResults.name = [];
  }
);
watch(
  () => registerState.surname,
  () => {
    registerExternalResults.surname = [];
  }
);
// watch(
// 	() => registerState.patronymic,
// 	() => {
// 		registerExternalResults.patronymic = [];
// 	}
// );
watch(
  () => registerState.username,
  () => {
    registerExternalResults.username = [];
  }
);
// watch(
// 	() => registerState.email,
// 	() => {
// 		registerExternalResults.email = [];
// 	}
// );
watch(
  () => registerState.password,
  () => {
    registerExternalResults.password = [];
  }
);
watch(
  () => registerState.smsCode,
  () => {
    registerExternalResults.smsCode = [];
  }
);
watch(
  () => registerState.passwordConfirmation,
  () => {
    registerExternalResults.passwordConfirmation = [];
  }
);
const registerRules = {
  name: {
    required: helpers.withMessage(t('profile.name_required'), required),
    //alpha: helpers.withMessage("Введите только буквы", alpha),
    maxLength: helpers.withMessage(t('profile.name_max_required'), maxLength(25))
  },
  surname: {
    required: helpers.withMessage(t('profile.surname_required'), required),
    maxLength: helpers.withMessage(t('profile.surname_max_required'), maxLength(25))
  },
  // patronymic: {
  // 	requiredIf: requiredIf(false),
  // },
  username: {
    required: helpers.withMessage(t('profile.phone_number_required'), required),
    minLength: helpers.withMessage(t('profile.correct_pnone_number'), minLength(17))
  },
  // email: {
  // 	required: helpers.withMessage("Введите емэил", required),
  // 	email: helpers.withMessage("Введите корректный емэил", email),
  // },
  password: {
    required: helpers.withMessage(t('profile.specify_password'), required),
    // alphaNum: helpers.withMessage(t('profile.password_number_letter'), alphaNum),
    minLength: helpers.withMessage(t('profile.password_least'), minLength(6))
  },
  smsCode: {
    required: helpers.withMessage(t('profile.specify_sms_code'), required),
    minLength: helpers.withMessage(t('profile.sms_code_least_min'), minLength(4)),
    maxLength: helpers.withMessage(t('profile.sms_code_least_max'), maxLength(4))
  },
  passwordConfirmation: {
    required: helpers.withMessage(t('profile.confirm_password'), required),
    sameAsPassword: helpers.withMessage(
      t('profile.confirm_sameAsPassword'),
      sameAs(toRef(registerState, 'password'))
    )
  },
  type: {
    required: helpers.withMessage(t('profile.specify_type_user'), required),
    in: helpers.withMessage(
      t('profile.type_required'),
      or(sameAs(ProfileType.customer), sameAs(ProfileType.executor))
    )
  }
};

const registerExternalResults = reactive({
  name: [],
  surname: [],
  // patronymic: [],
  username: [],
  // email: [],
  password: [],
  smsCode: [],
  passwordConfirmation: []
});

const registerValidator = useVuelidate(registerRules, registerState, {
  $externalResults: registerExternalResults
});
const registerErrorMessage = ref();
const onRegisterHandler = async (event) => {
  const type = event.submitter.value;
  Object.assign(registerState, { type });
  if (step.value === 1) {
    if (
      !(await registerValidator.value.username.$validate()) ||
      !(await registerValidator.value.name.$validate()) ||
      !(await registerValidator.value.surname.$validate())
    )
      return;
    var phone = registerState.username;
    if (phone.includes('-')) {
      phone = registerState.username.replace(/\s/g, '').toLowerCase().split('-');
      phone = phone[0].slice(4, 14) + phone[1] + phone[2];
    }
    await onCheckPhone(phone);
    if (!result.value.status)
      Resend(), (step.value = 2), (registerExternalResults.smsCode[0] = null);
    else registerExternalResults.smsCode[0] = t('profile.user_with_this_number');
  } else if (step.value === 2) {
    if (!(await registerValidator.value.smsCode.$validate())) return;
    var bodyFormData = new FormData();
    var send = false;
    bodyFormData.append('required_id', required_id.value);
    bodyFormData.append('code', registerState.smsCode);
    await onSend(bodyFormData, send);
    if (!result.value.isChecked) registerErrorMessage.value = t('profile.wrong_sms_code');
    else step.value = 3;
  } else {
    if (
      !(await registerValidator.value.password.$validate()) ||
      !(await registerValidator.value.passwordConfirmation.$validate())
    )
      return;

    if (registerState.username.includes('-')) {
      registerState.username = registerState.username.replace(/\s/g, '').toLowerCase().split('-');
      registerState.username =
        registerState.username[0] + registerState.username[1] + registerState.username[2];
    }
    await onRegister(registerState).catch(({ response }) => {
      if (response?.data?.error) {
        const { type, fields, message } = response?.data?.error;
        if (type == 'validation') {
          Object.assign(registerExternalResults, fields);
        }
        if (type == 'auth') {
          registerErrorMessage.value = message;
        }

        throw new Error({ type, fields, message });
      }
    });
    /*onRegister(registerState).catch(({ response }) => {
      try {
        console.log(response?.data)

        const { type, fields, message } = response?.data?.error || {};

        if (type === 'validation') {
          Object.assign(registerExternalResults, fields);
        }

        if (type === 'auth') {
          registerErrorMessage.value = message;
        }

        throw new Error(
          `Registration error - Type: ${type}, Fields: ${JSON.stringify(
            fields
          )}, Message: ${message}`
        );
        
      } catch (error) {
        console.error('Error during error handling:', error);
      }
    });*/
  }
  router.push('/account');
  emit('successful', profile.value);
};
</script>
