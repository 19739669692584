import axios from '@/utils/axios';

export const getSettingsSite = () => {
  return axios.get(`/fika/workbase/v1.0.1/settings`);
};
export const get = () => {
  return axios.get(`/fika/workbase/v1.0.1/profile/data`);
};
export const updataeTG = (id, body) => {
  return axios.put(`/fika/workbase/v1.0.1/profile/126`, body);
};
