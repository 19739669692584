<template>
  <div>
    <div id="language" class="relative">
      <button
        v-if="user_type == 'executor'"
        class="xl:flex my-2 hidden border transition-all duration-300 ease-out hover:ease-in rounded-full items-center px-3 py-2"
        :class="{
          'border-white hover:bg-white hover:text-primary': headerType == 'secondary',
          'border-primary hover:bg-primary hover:text-white text-primary': headerType == 'primary'
        }"
        @click.stop="show = !show"
      >
        <svg
          v-if="!copied"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
          width="30px"
          height="24px"
          class="-ml-1"
        >
          <path
            d="M 18 2 C 16.35499 2 15 3.3549904 15 5 C 15 5.1909529 15.021791 5.3771224 15.056641 5.5585938 L 7.921875 9.7207031 C 7.3985399 9.2778539 6.7320771 9 6 9 C 4.3549904 9 3 10.35499 3 12 C 3 13.64501 4.3549904 15 6 15 C 6.7320771 15 7.3985399 14.722146 7.921875 14.279297 L 15.056641 18.439453 C 15.021555 18.621514 15 18.808386 15 19 C 15 20.64501 16.35499 22 18 22 C 19.64501 22 21 20.64501 21 19 C 21 17.35499 19.64501 16 18 16 C 17.26748 16 16.601593 16.279328 16.078125 16.722656 L 8.9433594 12.558594 C 8.9782095 12.377122 9 12.190953 9 12 C 9 11.809047 8.9782095 11.622878 8.9433594 11.441406 L 16.078125 7.2792969 C 16.60146 7.7221461 17.267923 8 18 8 C 19.64501 8 21 6.6450096 21 5 C 21 3.3549904 19.64501 2 18 2 z M 18 4 C 18.564129 4 19 4.4358706 19 5 C 19 5.5641294 18.564129 6 18 6 C 17.435871 6 17 5.5641294 17 5 C 17 4.4358706 17.435871 4 18 4 z M 6 11 C 6.5641294 11 7 11.435871 7 12 C 7 12.564129 6.5641294 13 6 13 C 5.4358706 13 5 12.564129 5 12 C 5 11.435871 5.4358706 11 6 11 z M 18 18 C 18.564129 18 19 18.435871 19 19 C 19 19.564129 18.564129 20 18 20 C 17.435871 20 17 19.564129 17 19 C 17 18.435871 17.435871 18 18 18 z"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          class="text-green-600"
          viewBox="0 0 24 24"
          width="24px"
          height="24px"
        >
          <path
            d="M 12 2 C 6.486 2 2 6.486 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 10.874 21.803984 9.7942031 21.458984 8.7832031 L 19.839844 10.402344 C 19.944844 10.918344 20 11.453 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 7.589 7.589 4 12 4 C 13.633 4 15.151922 4.4938906 16.419922 5.3378906 L 17.851562 3.90625 C 16.203562 2.71225 14.185 2 12 2 z M 21.292969 3.2929688 L 11 13.585938 L 7.7070312 10.292969 L 6.2929688 11.707031 L 11 16.414062 L 22.707031 4.7070312 L 21.292969 3.2929688 z"
          />
        </svg>
      </button>
      <div
        v-show="show"
        class="absolute right-0 z-50 mt-5 bg-white rounded-md shadow-xl text-center border border-primary w-32 -left-2/3"
      >
        <button
          class="flex justify-center px-4 py-2 text-base text-black hover:bg-primary hover:text-white hover:rounded-t-md transition-all duration-300 ease-out hover:ease-in w-full"
          @click="showQr = !showQr"
        >
          {{ $t('profile.share_profile') }}
        </button>
        <!--<button
          class="
            flex
            justify-center
            px-4
            py-2
            text-base text-black
            hover:bg-primary hover:text-white hover:rounded-b-md
            transition-all
            duration-300
            ease-out
            hover:ease-in
            w-full
          "
          @click="copy()"
        >
          <span v-if="copied" class="text-green-500"> {{ copied }} </span> -->
        <!-- <span> Скопировать ссылку </span>
        </button>-->
      </div>
      <!-- <div
                class="
                    xl:hidden
                    flex
                    items-center
                    w-full
                    justify-center
                    py-3
                    mb-10
                "
            >
                <button class="px-2 py-1 rounded-md m-2">OZ</button>
                <button class="px-2 py-1 rounded-md m-2">УЗ</button>
                <button class="border px-2 py-1 rounded-md m-2">РУ</button>
                <button class="px-2 py-1 rounded-md m-2">EN</button>
            </div> -->
    </div>
    <c-modal v-slot="{ close }" v-model="showQr">
      <button
        class="absolute top-3 right-3 bg-gray-300 hover:bg-primary hover:text-white transition duration-200 rounded-full p-2"
        @click="close"
      >
        <h-x class="w-3 h-3" />
      </button>

      <div class="share-block">
        <h3 class="sm:text-base text-sm md:mb-5 mb-3" style="padding-right: 32px;">
          {{ $t('profile.share_your_profile') }}
        </h3>
        <h4 class="sm:text-base text-sm md:mb-5 mb-3">
          {{ $t('profile.share_link_profile') }}
        </h4>
        <div class="share-link md:mb-5 mb-3" @click="copy()">
          <div v-text="`https://workbase.uz/profile/${slug}`" />
          <svg
            v-if="!copied"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.4 22.75H7.6C3.21 22.75 1.25 20.79 1.25 16.4V12.6C1.25 8.21 3.21 6.25 7.6 6.25H10.6C11.01 6.25 11.35 6.59 11.35 7C11.35 7.41 11.01 7.75 10.6 7.75H7.6C4.02 7.75 2.75 9.02 2.75 12.6V16.4C2.75 19.98 4.02 21.25 7.6 21.25H11.4C14.98 21.25 16.25 19.98 16.25 16.4V13.4C16.25 12.99 16.59 12.65 17 12.65C17.41 12.65 17.75 12.99 17.75 13.4V16.4C17.75 20.79 15.79 22.75 11.4 22.75Z"
              fill="#87898E"
            />
            <path
              d="M17.0001 14.15H13.8001C10.9901 14.15 9.8501 13.01 9.8501 10.2V6.99999C9.8501 6.69999 10.0301 6.41999 10.3101 6.30999C10.5901 6.18999 10.9101 6.25999 11.1301 6.46999L17.5301 12.87C17.7401 13.08 17.8101 13.41 17.6901 13.69C17.5801 13.97 17.3001 14.15 17.0001 14.15ZM11.3501 8.80999V10.2C11.3501 12.19 11.8101 12.65 13.8001 12.65H15.1901L11.3501 8.80999Z"
              fill="#87898E"
            />
            <path
              d="M15.6001 2.75H11.6001C11.1901 2.75 10.8501 2.41 10.8501 2C10.8501 1.59 11.1901 1.25 11.6001 1.25H15.6001C16.0101 1.25 16.3501 1.59 16.3501 2C16.3501 2.41 16.0101 2.75 15.6001 2.75Z"
              fill="#87898E"
            />
            <path
              d="M7 5.75C6.59 5.75 6.25 5.41 6.25 5C6.25 2.93 7.93 1.25 10 1.25H12.62C13.03 1.25 13.37 1.59 13.37 2C13.37 2.41 13.03 2.75 12.62 2.75H10C8.76 2.75 7.75 3.76 7.75 5C7.75 5.41 7.41 5.75 7 5.75Z"
              fill="#87898E"
            />
            <path
              d="M19.1899 17.25C19.0561 17.25 18.9399 17.1339 18.9399 17C18.9399 16.8661 19.0561 16.75 19.1899 16.75C20.6077 16.75 21.7499 15.5946 21.7499 14.19V8C21.7499 7.86614 21.8661 7.75 21.9999 7.75C22.1338 7.75 22.2499 7.86614 22.2499 8V14.19C22.2499 15.8739 20.8738 17.25 19.1899 17.25Z"
              fill="#87898E"
              stroke="#87898E"
            />
            <path
              d="M22 8.74999H19C16.34 8.74999 15.25 7.65999 15.25 4.99999V1.99999C15.25 1.69999 15.43 1.41999 15.71 1.30999C15.99 1.18999 16.31 1.25999 16.53 1.46999L22.53 7.46999C22.74 7.67999 22.81 8.00999 22.69 8.28999C22.58 8.56999 22.3 8.74999 22 8.74999ZM16.75 3.80999V4.99999C16.75 6.82999 17.17 7.24999 19 7.24999H20.19L16.75 3.80999Z"
              fill="#87898E"
            />
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            class="text-green-600"
            viewBox="0 0 24 24"
            width="24px"
            height="24px"
          >
            <path
              d="M 12 2 C 6.486 2 2 6.486 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 10.874 21.803984 9.7942031 21.458984 8.7832031 L 19.839844 10.402344 C 19.944844 10.918344 20 11.453 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 7.589 7.589 4 12 4 C 13.633 4 15.151922 4.4938906 16.419922 5.3378906 L 17.851562 3.90625 C 16.203562 2.71225 14.185 2 12 2 z M 21.292969 3.2929688 L 11 13.585938 L 7.7070312 10.292969 L 6.2929688 11.707031 L 11 16.414062 L 22.707031 4.7070312 L 21.292969 3.2929688 z"
            />
          </svg>
        </div>
        <h4 class="sm:text-base text-sm md:mb-5 mb-3">
          {{ $t('profile.share_link_social') }}
        </h4>
        <div class="share-social mb-5 flex justify-center">
          <shareButton :post-props="store" />
        </div>
        <hr class="mb-3" />
        <h4 class="sm:text-base text-sm md:mb-5 mb-3">
          {{ $t('profile.qr_code') }}
        </h4>
        <div class="qr-code-border md:mb-5 mb-3">
          <div class="qr-code">
            <vue-qrcode :value="`https://workbase.uz/profile/${slug}`" :options="{ width: 400 }" />
          </div>
        </div>
        <!-- <h4 class="sm:text-base text-sm md:mb-5 mb-3"> Поделитесь своим QR - кодом в социальных сетях </h4> -->
        <!-- <div class="qr-code-social mb-5 flex justify-center">
                    Facebook
          <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M60 30.5C60 13.9298 46.5703 0.5 30 0.5C13.4298 0.5 0 13.9298 0 30.5C0 45.4766 10.9688 57.8867 25.3125 60.1367V39.1719H17.6953V30.5H25.3125V23.8906C25.3125 16.373 29.7891 12.2188 36.6445 12.2188C39.9258 12.2188 43.3594 12.8047 43.3594 12.8047V20.1875H39.5742C35.8476 20.1875 34.6875 22.502 34.6875 24.875V30.5H43.0078L41.6777 39.1719H34.6875V60.1367C49.0312 57.8867 60 45.4766 60 30.5Z" fill="#FB923C"/>
          <path d="M41.6778 39.1719L43.0078 30.5H34.6876V24.875C34.6876 22.502 35.8477 20.1875 39.5743 20.1875H43.3594V12.8047C43.3594 12.8047 39.9258 12.2188 36.6446 12.2188C29.7892 12.2188 25.3126 16.373 25.3126 23.8906V30.5H17.6953V39.1719H25.3126V60.1367C26.8418 60.377 28.4063 60.5 30.0001 60.5C31.5938 60.5 33.1583 60.377 34.6876 60.1367V39.1719H41.6778Z" fill="#FCFCFD"/>
          </svg>

          Instagram

          <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="30" cy="30.5" r="30" fill="#FB923C"/>
          <path d="M38.0838 10.5H21.9162C15.3456 10.5 10 15.8456 10 22.4162V38.5838C10 45.1544 15.3456 50.5 21.9162 50.5H38.0838C44.6544 50.5 50 45.1544 50 38.5838V22.4162C49.9999 15.8456 44.6543 10.5 38.0838 10.5ZM45.976 38.5838C45.976 42.9425 42.4425 46.476 38.0838 46.476H21.9162C17.5575 46.476 14.024 42.9425 14.024 38.5838V22.4162C14.024 18.0574 17.5575 14.524 21.9162 14.524H38.0838C42.4425 14.524 45.976 18.0574 45.976 22.4162V38.5838Z" fill="#FCFCFD"/>
          <path d="M30.0002 20.1545C24.2957 20.1545 19.6548 24.7955 19.6548 30.4999C19.6548 36.2043 24.2957 40.8453 30.0002 40.8453C35.7047 40.8453 40.3456 36.2044 40.3456 30.4999C40.3456 24.7954 35.7047 20.1545 30.0002 20.1545ZM30.0002 36.8214C26.509 36.8214 23.6788 33.9912 23.6788 30.5C23.6788 27.0087 26.509 24.1785 30.0002 24.1785C33.4915 24.1785 36.3216 27.0087 36.3216 30.5C36.3216 33.9911 33.4914 36.8214 30.0002 36.8214Z" fill="#FCFCFD"/>
          <path d="M40.3657 22.7112C41.7348 22.7112 42.8447 21.6014 42.8447 20.2323C42.8447 18.8632 41.7348 17.7533 40.3657 17.7533C38.9966 17.7533 37.8867 18.8632 37.8867 20.2323C37.8867 21.6014 38.9966 22.7112 40.3657 22.7112Z" fill="#FCFCFD"/>
          </svg>

          Telegram

          <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M30 60.5C46.5685 60.5 60 47.0685 60 30.5C60 13.9315 46.5685 0.5 30 0.5C13.4315 0.5 0 13.9315 0 30.5C0 47.0685 13.4315 60.5 30 60.5Z" fill="#FB923C"/>
          <path d="M11.1725 31.9675C14.6795 30.0357 18.5943 28.4235 22.252 26.803C28.5448 24.1487 34.8625 21.5405 41.244 19.1122C42.4856 18.6985 44.7165 18.2938 44.9353 20.1338C44.8155 22.7383 44.3228 25.3276 43.9848 27.9168C43.1269 33.6108 42.1354 39.2853 41.1685 44.9606C40.8354 46.851 38.4673 47.8296 36.952 46.6198C33.3105 44.1601 29.641 41.7243 26.046 39.2076C24.8684 38.011 25.9604 36.2926 27.0121 35.4381C30.0114 32.4823 33.1921 29.9711 36.0346 26.8626C36.8014 25.011 34.5359 26.5715 33.7886 27.0496C29.6826 29.8791 25.6771 32.8813 21.3481 35.3681C19.1369 36.5853 16.5596 35.5451 14.3494 34.8658C12.3676 34.0453 9.46359 33.2187 11.1723 31.9676L11.1725 31.9675Z" fill="#FCFCFD"/>
          </svg>

          Twitter

          <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 30.5C0 13.9315 13.4315 0.5 30 0.5C46.5685 0.5 60 13.9315 60 30.5C60 47.0685 46.5685 60.5 30 60.5C13.4315 60.5 0 47.0685 0 30.5Z" fill="#FB923C"/>
          <path d="M29.1015 24.8844L29.1645 25.9224L28.1152 25.7953C24.2961 25.3081 20.9596 23.6556 18.1267 20.8804L16.7417 19.5034L16.385 20.5203C15.6295 22.7871 16.1122 25.181 17.686 26.791C18.5254 27.6808 18.3365 27.8079 16.8886 27.2783C16.385 27.1088 15.9443 26.9817 15.9023 27.0453C15.7554 27.1935 16.2591 29.1214 16.6578 29.884C17.2034 30.9433 18.3155 31.9814 19.5326 32.5957L20.5609 33.083L19.3438 33.1042C18.1686 33.1042 18.1267 33.1254 18.2526 33.5702C18.6723 34.9473 20.33 36.409 22.1767 37.0446L23.4777 37.4895L22.3445 38.1674C20.6658 39.1419 18.6933 39.6927 16.7207 39.7351C15.7764 39.7563 15 39.841 15 39.9046C15 40.1164 17.5601 41.3028 19.05 41.7689C23.5197 43.1459 28.8287 42.5527 32.8157 40.2012C35.6486 38.5276 38.4815 35.2015 39.8035 31.9814C40.517 30.2654 41.2305 27.13 41.2305 25.6259C41.2305 24.6513 41.2934 24.5242 42.4685 23.3591C43.161 22.6811 43.8116 21.9397 43.9375 21.7278C44.1473 21.3253 44.1263 21.3253 43.0561 21.6854C41.2724 22.321 41.0206 22.2362 41.902 21.2829C42.5525 20.605 43.3289 19.3763 43.3289 19.0161C43.3289 18.9526 43.0141 19.0585 42.6574 19.2492C42.2797 19.461 41.4403 19.7788 40.8108 19.9694L39.6776 20.3296L38.6494 19.6305C38.0828 19.2492 37.2854 18.8255 36.8657 18.6983C35.7955 18.4017 34.1587 18.4441 33.1935 18.7831C30.5704 19.7364 28.9126 22.1939 29.1015 24.8844Z" fill="#FCFCFD"/>
          </svg>

          Email

          <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="0.5" width="60" height="60" rx="30" fill="#FB923C"/>
          <path d="M37.5 43.25H22.5C18 43.25 15 41 15 35.75V25.25C15 20 18 17.75 22.5 17.75H37.5C42 17.75 45 20 45 25.25V35.75C45 41 42 43.25 37.5 43.25Z" stroke="#FCFCFD" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M37.5 26L32.805 29.75C31.26 30.98 28.725 30.98 27.18 29.75L22.5 26" stroke="#FCFCFD" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
      </div> -->
      </div>
    </c-modal>
  </div>
</template>
<script setup>
import { computed, onMounted, onUnmounted, ref } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
import store from '../../store/index.js';
import shareButton from '@/components/userMenu/shareButton';
// eslint-disable-next-line no-unused-vars
//import axios from '@/utils/axios';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const show = ref(false);

const route = useRoute();

const escapePressedListener = (e) => {
  if (e.keyCode == 27) show.value = false;
};
const outsideClickListener = () => {
  show.value = false;
};
const copied = ref('');
const slug = ref('');
const showQr = ref(false);
// const profileStorage = localStorage.getItem('profile');
// const StorageItem = (profileStorage && JSON.parse(profileStorage)) || {};
const user_type = computed(() => store?.state?.profileStore?.type);
onMounted(() => {
  document.addEventListener('keyup', escapePressedListener);
  document.addEventListener('click', outsideClickListener);
});
onUnmounted(() => {
  document.removeEventListener('keyup', escapePressedListener);
  document.removeEventListener('click', outsideClickListener);
});

const headerType = computed(() => route.meta?.header || 'secondary');

const copy = () => {
  show.value = true;
  navigator.clipboard.writeText(`https://workbase.uz/profile/${slug.value}`);
  copied.value = t('profile.link_copy');
  setTimeout(() => {
    copied.value = '';
  }, 1000);
};
onMounted(async () => {
  // const res = await axios.get(`/fika/workbase/v1.0.1/profile/data`);
  // const data = await res.data;
  slug.value = store?.state?.profileStore?.slug;
});
</script>
<style lang="scss">
.share-block {
  width: 544px;
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 18px;
    text-align: center;
  }
  .qr-code {
    width: 190px;
    height: 190px;
    margin: 0 auto;
    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }
  .share-link {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    height: 56px;
    background: #fcfcfd;
    border: 2px solid #dfdfe6;
    border-radius: 12px;
    svg {
      position: absolute;
      right: 35px;
    }
  }
  hr {
    color: #afafaf;
  }
  .share-social svg,
  .qr-code-social svg {
    margin: 0 10px;
  }
  .qr-code-border {
    border: 2px solid #dfdfe6;
    border-radius: 12px;
  }
}
@media (max-width: 640px) {
  .share-block {
    width: 100%;
  }
}
</style>
