import { createI18n } from 'vue-i18n';
import uz from './locales/uz.json';
import ru from './locales/ru.json';
import en from './locales/en.json';
import oz from './locales/oz.json';

const messages = {
  ru: Object.assign(ru),
  uz: Object.assign(uz),
  en: Object.assign(en),
  oz: Object.assign(oz)
};

const i18n = createI18n({
  locale: sessionStorage.getItem('lang') || 'ru',
  fallbackLocale: 'uz',
  legacy: false,
  messages
});

export default i18n;
