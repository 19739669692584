import { login } from '@/api/auth';
import { readonly, ref } from 'vue';
import { profile as profileRef } from '../profile';
import { token as tokenRef } from './token';
import store from '@/store';
const isLogining = ref(false);
const isLogined = ref(false);
export default function useLogin() {
  const onLogin = async ({ username, password, type }) => {
    isLogining.value = true;
    await login({ username, password, type })
      .then((response) => response?.data)
      .then((data) => {
        const { token, profile } = data;
        tokenRef.value = token.value;
        profileRef.value = profile;
        // $cookies.set("token" , token.value,"1d")
        store.commit('changeImgUrl', {
          avatar: profile?.avatar || null,
          avatar_thumb: profile.avatar_thumb
        });
        store.commit('changeProfile', profile);
        isLogined.value = true;
        return readonly(profileRef);
      })
      .finally(() => (isLogining.value = false));
  };
  return {
    onLogin,
    isLogining: readonly(isLogining),
    profile: readonly(profileRef),
    isLogined: readonly(isLogined)
  };
}
