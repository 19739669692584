import { createStore } from 'vuex';
import useProfile from '@/composables/profile';
const { profile } = useProfile();
// Modules
import specialty from './modules/specialty';
import contact from './modules/contact';

import axios from '@/utils/axios';

const token = localStorage.getItem('token');

const state = {
  avatar_url: profile.value?.avatar,
  avatar_thumb_url: profile.value?.avatar_thumb,
  profileStore: profile,
  scrollProps: null,
  notificationList: [],
};

const mutations = {
  changeImgUrl(state, { avatar, avatar_thumb = null }) {
    state.avatar_url = avatar;
    state.avatar_thumb_url = avatar_thumb;
    state.profileStore.avatar = avatar;
    state.profileStore.avatar_thumb = avatar_thumb;
  },
  changeProfile(state, profileStore) {
    state.profileStore = profileStore;
  },
  changeScrollProps(state, props) {
    state.scrollProps = props;
  },
  setNotificationList(state, data) {
    state.notificationList = null;
    state.notificationList = data;
  }
};
const actions = {
  async requestgetNotification({ commit }) {
    try {
      let res = await axios
      .get(process.env.VUE_APP_API_URL + '/fika/workbase/v1.0.1/profile/notification?perPage=5', {
        headers: {
          Authorization: 'Bearer' + token,
          'x-access-token': token
        }
      })
      commit('setNotificationList', res.data); 
    } catch (error) {
      /* eslint-disable */
      console.log(error)
    }
  },
};

const getters = {
  notificationList(state) {
    return state.notificationList
  },
};

const modules = {
  specialty,
  contact
};

export default createStore({
  state,
  getters,
  actions,
  mutations,
  modules
});
