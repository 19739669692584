import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import CGrid from '@/components/c-grid';
import CDropdown from '@/components/c-dropdown';
import CDropdownItem from '@/components/c-dropdown-item';
import GoBack from '@/components/go-back.vue';
import './assets/styles/index.css';
import './assets/styles/tailwind.css';
import './assets/styles/media.css';
import './assets/fonts/Aeroport/stylesheet.css';

import * as HeroIcons from '@/components/icons/hero';
import * as UI from '@/components/ui';
import i18n from './I18n';
//import pusher from './pusher';
import Maska from 'maska';
const store = './store/index.js';
const app = createApp(App);
import VueQrcode from '@chenfengyuan/vue-qrcode';
import Toast from 'vue-toastification';
import Tooltip from "@programic/vue3-tooltip";
import print from 'vue3-print-nb';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';
import '@programic/vue3-tooltip/dist/index.css';
import VueCookies from 'vue-cookies';
const options = {
  // You can set your default options here
  timeout: 5000
};
for (const component in HeroIcons) {
  app.component(component, HeroIcons[component].default);
}

for (const component in UI) {
  app.component(component, UI[component].default);
}
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox.css';

Fancybox.bind('[data-fancybox]', {
  hideClass: ''
});
app
  .component('CGrid', CGrid)
  .component('c-dropdown', CDropdown)
  .component('c-dropdown-item', CDropdownItem)
  .component('go-back', GoBack)
  .component(VueQrcode.name, VueQrcode)
  .component('v-select', vSelect)
  .use(Maska)
  .use(VueCookies)
  .use(store)
  .use(i18n)
  .use(router)
  .use(print)
//  .use(pusher)
  .use(Toast, options)
  .use(Tooltip, options)
  .mount('#app');
