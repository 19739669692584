const state = {
  contactModelOpen: false
};

const mutations = {
  // specialtiesModal(state, payload) {
  //   state.mainService = payload || null
  //   state.specialtiesModalOpen = !state.specialtiesModalOpen;
  // },
  contactModal(state) {
    state.contactModelOpen = !state.contactModelOpen;
  }
  // setSpecialty(state, data) {
  //   state.specialtyList = null;
  //   state.specialtyList = data;
  // },
  // setServicesList(state, data) {
  //   state.servicesList = data;
  // },
  // setChildServices(state, data) {
  //   state.childServices = data;
  // },
  // setGrandsons(state, data){
  //   state.grandSonsServiceList = data
  // }
};
const actions = {
  // requestgetSpecialty({ commit, dispatch }) {
  //   getSpecialty(JSON.parse(localStorage.profile).id)
  //     .then((res) => {
  //       dispatch("requestGetSerivices");
  //       commit("setSpecialty", res.data);
  //     })
  //     .catch((error) => {
  //     });
  // },
  // requestGetSerivices({ commit }) {
  //   getServicesList()
  //     .then((res) => {
  //       commit("setServicesList", res.data.collection);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // },
  // getChildService(ctx, payload) {
  //   getServicesChilds(payload)
  //     .then((res) => {
  //       ctx.commit("setChildServices", res.data.category.priority_services);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // },
  // getGransonService(ctx, payload) {
  //   getGransonServices(payload)
  //     .then((res) => {
  //       console.log(res.data);
  //       ctx.commit("setGrandsons", res.data.collection);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // },
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
