<script setup>
import { computed } from '@vue/runtime-core';

const props = defineProps({
  loading: {
    type: Boolean,
    required: false,
    default() {
      return false;
    }
  },
  type: {
    type: String,
    required: false,
    default() {
      return 'text';
    },
    validator(value) {
      return ['text'].includes(value);
    }
  }
});
const isLoading = computed(() => props.loading);
const isText = computed(() => props.type == 'text');
</script>
<template>
  <div>
    <div
      v-if="isLoading"
      :class="{
        'pt-1.5 pb-0.5': isText
      }"
    >
      <div class="h-6 w-96 bg-primary/10 rounded-lg animate-pulse" />
    </div>
    <div v-else>
      <slot />
    </div>
  </div>
</template>
