import { isAuthentificated } from '@/composables/auth';
import router from '@/router';
import axios from 'axios';
import { token, useRefreshToken } from '@/composables/auth/token.js';
import { profile } from '@/composables/profile';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': sessionStorage.getItem('lang') || 'ru'
  }
});

instance.interceptors.request.use(
  async function (config) {
    if (isAuthentificated.value) {
      config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

let refreshing = false;
instance.interceptors.response.use(
  async function (response) {
    return response;
  },
  async function (error) {
    const { config, response } = error;
    if (response?.status == 401 && !refreshing) {
      refreshing = true;
      try {
        const { promise, onRefresh } = useRefreshToken();
        if (!promise.value) {
          onRefresh();
        }
        await promise.value;
        refreshing = false;
        return instance(config);
      } catch (e) {
        refreshing = false;
        clearAndNavigateToIndex();
        return Promise.reject(e);
      }
    }

    return Promise.reject(error);
  }
);

function clearAndNavigateToIndex() {
  profile.value = null;
  token.value = null;
  router.push('/');
}

export default instance;
