<template>
  <form class="block sm:max-w-[24rem] sm:w-96 w-full" @submit.prevent="onRegisterHandler">
    <h3 class="block text-xl md:text-xl text-center mb-6">
      {{ $t('profile.password_title') }}
    </h3>
    <p v-if="registerErrorMessage" class="mb-6 text-red-500 font-thin text-sm">
      {{ registerErrorMessage }}
    </p>
    <p v-if="registerExternalResults.smsCode[0]" class="mb-6 text-red-500 font-thin text-sm">
      {{ registerExternalResults.smsCode[0] }}
    </p>
    <p v-if="step == 2" class="mb-5 text-primary text-sm">
      {{
        $t('profile.sms_code_title', { name: registerState.name, username: registerState.username })
      }}
    </p>
    <div class="flex flex-col">
      <div v-show="step === 1">
        <c-input
          v-model.trim="registerState.username"
          v-maska="['+998 ## ###-##-##', '+998 ## ###-##-##']"
          name="username"
          class="w-full"
          type="text"
          :valid="!registerValidator.username.$error"
        >
          {{ $t('profile.phone_number') }}
        </c-input>
        <div class="mt-0.5 mb-3.5">
          <div
            v-for="error in registerValidator.username.$errors"
            :key="error.$uid"
            class="text-red-500"
          >
            {{ error.$message }}
          </div>
        </div>
      </div>
      <div v-show="step === 2">
        <c-input
          v-model="registerState.smsCode"
          v-maska="['####', '####']"
          name="smsCode"
          class="w-full"
          type="text"
          eye
          :valid="!registerValidator.smsCode.$error"
        >
          {{ $t('profile.sms_code') }}
        </c-input>
        <div class="mt-0.5 mb-2">
          <div
            v-for="error in registerValidator.smsCode.$errors"
            :key="error.$uid"
            class="text-red-500"
          >
            {{ error.$message }}
          </div>
        </div>
        <p v-if="countDown > 0" class="mb-3 text-primary text-sm float-right">
          {{ `00:${countDown}` }}
        </p>
        <button
          v-else
          class="mb-3 text-primary text-sm float-right underline"
          type="button"
          @click="Resend()"
        >
          {{ $t('profile.resend') }}
        </button>
      </div>
      <div v-show="step === 3">
        <c-input
          v-model="registerState.password"
          name="password"
          class="w-full"
          type="password"
          eye
          :valid="!registerValidator.password.$error"
        >
          {{ $t('profile.password') }}
        </c-input>
        <div class="mt-0.5 mb-3.5">
          <div
            v-for="error in registerValidator.password.$errors"
            :key="error.$uid"
            class="text-red-500"
          >
            {{ error.$message }}
          </div>
        </div>
        <c-input
          v-model="registerState.password_confirmation"
          name="password_confirmation"
          class="w-full"
          type="password"
          eye
          :valid="!registerValidator.password_confirmation.$error"
        >
          {{ $t('profile.password_confirmation') }}
        </c-input>
        <div class="mt-0.5 mb-3.5">
          <div
            v-for="error in registerValidator.password_confirmation.$errors"
            :key="error.$uid"
            class="text-red-500"
          >
            {{ error.$message }}
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-4">
      <c-button value="customer" :disabled="isRegistering" :loading="isRegistering">
        <span v-if="step == 3">{{ $t('profile.confirm_and_login') }}</span>
        <span v-else>{{ step == 2 ? $t('profile.confirm') : $t('profile.next') }}</span>
      </c-button>
    </div>
  </form>
</template>

<script setup>
import { useResetApi, isAuthentificated, useSmsApi, useLogin } from '@/composables/auth';
import { ProfileType } from '@/utils/enums';
import useVuelidate from '@vuelidate/core';
import {
  // email,
  helpers,
  required,
  // requiredIf,
  // alphaNum,
  maxLength,
  minLength,
  sameAs
} from '@vuelidate/validators';
import { or } from '@vueuse/core';
import { reactive, ref, toRef, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const router = useRouter();

const emit = defineEmits(['successful']);

const { onChange, result: res } = useResetApi();
const { onSend, onCheckPhone, result } = useSmsApi();
const { onLogin, profile } = useLogin();
const step = ref(1);
const countDown = ref(59);
const required_id = ref(null);
const registerState = reactive({
  username: '',
  password: '',
  smsCode: '',
  password_confirmation: '',
  type: ProfileType.customer
});
const countDownTimer = () => {
  if (countDown.value > 0) {
    setTimeout(() => {
      countDown.value -= 1;
      countDownTimer();
    }, 1000);
  }
};
const Resend = async () => {
  var bodyFormData = new FormData();
  var send = true;
  var phone = registerState.username.replace(/\s/g, '').toLowerCase().split('-');
  phone = phone[0].slice(4, 14) + phone[1] + phone[2];
  bodyFormData.append('phone', phone);
  bodyFormData.append('type', "reset_pass");
  await onSend(bodyFormData, send);
  required_id.value = result.value.required_id;
  countDown.value = 59;
  countDownTimer();
};
watch(
  () => registerState.username,
  () => {
    registerExternalResults.username = [];
  }
);
watch(
  () => registerState.password,
  () => {
    registerExternalResults.password = [];
  }
);
watch(
  () => registerState.smsCode,
  () => {
    registerExternalResults.smsCode = [];
  }
);
watch(
  () => registerState.password_confirmation,
  () => {
    registerExternalResults.password_confirmation = [];
  }
);
const registerRules = {
  username: {
    required: helpers.withMessage(t('profile.phone_number_required'), required),
    minLength: helpers.withMessage(t('profile.correct_pnone_number'), minLength(17))
  },
  password: {
    required: helpers.withMessage(t('profile.specify_password'), required),
    // alphaNum: helpers.withMessage(t('profile.password_number_letter'), alphaNum),
    minLength: helpers.withMessage(t('profile.password_least'), minLength(6))
  },
  smsCode: {
    required: helpers.withMessage(t('profile.specify_sms_code'), required),
    minLength: helpers.withMessage(t('profile.sms_code_least_min'), minLength(4)),
    maxLength: helpers.withMessage(t('profile.sms_code_least_max'), maxLength(4))
  },
  password_confirmation: {
    required: helpers.withMessage(t('profile.confirm_password'), required),
    sameAsPassword: helpers.withMessage(
      t('profile.confirm_sameAsPassword'),
      sameAs(toRef(registerState, 'password'))
    )
  },
  type: {
    required: helpers.withMessage(t('profile.specify_type_user'), required),
    in: helpers.withMessage(
      t('profile.type_required'),
      or(sameAs(ProfileType.customer), sameAs(ProfileType.executor))
    )
  }
};

const registerExternalResults = reactive({
  username: [],
  password: [],
  smsCode: [],
  password_confirmation: []
});

const registerValidator = useVuelidate(registerRules, registerState, {
  $externalResults: registerExternalResults
});
const registerErrorMessage = ref();
const onRegisterHandler = async (event) => {
  const type = event.submitter.value;
  Object.assign(registerState, { type });
  if (step.value === 1) {
    if (!(await registerValidator.value.username.$validate())) return;
    var phone = registerState.username;
    if (phone.includes('-')) {
      phone = registerState.username.replace(/\s/g, '').toLowerCase().split('-');
      phone = phone[0].slice(4, 14) + phone[1] + phone[2];
    }
    await onCheckPhone(phone);
    if (result.value.status)
      Resend(), (step.value = 2), (registerExternalResults.smsCode[0] = null);
    else registerExternalResults.smsCode[0] = t('profile.user_not_reg');
  } else if (step.value === 2) {
    if (!(await registerValidator.value.smsCode.$validate())) return;
    var bodyFormData = new FormData();
    var send = false;
    bodyFormData.append('required_id', required_id.value);
    bodyFormData.append('code', registerState.smsCode);
    await onSend(bodyFormData, send);
    if (!result.value.isChecked) registerErrorMessage.value = t('profile.wrong_sms_code');
    else (registerErrorMessage.value = null), (step.value = 3);
  } else {
    if (
      !(await registerValidator.value.password.$validate()) &&
      !(await registerValidator.value.password_confirmation.$validate())
    )
      return;
    if (registerState.username.includes('-')) {
      registerState.username = registerState.username.replace(/\s/g, '').toLowerCase().split('-');
      registerState.username =
        registerState.username[0] + registerState.username[1] + registerState.username[2];
    }
    await onChange({ ...registerState, phone: registerState.username });
    if (res.value.status) {
      await onLogin({
        username: registerState.username,
        password: registerState.password,
        type: 'customer'
      });
    } else {
      registerErrorMessage.value = t('profile.error');
    }
  }
  emit('successful', profile.value);
};
watch(isAuthentificated, () => {
  if (registerState.type === 'executor' && isAuthentificated.value) {
    router.push({
      name: 'account'
    });
  }
});
</script>
