import { get, getProfile, update } from '@/api/profile';
import { useLocalStorage } from '@vueuse/core';
import { ref } from 'vue';
import useApi from '../api';

export const profile = useLocalStorage('profile', null, {
  serializer: {
    write(value) {
      return JSON.stringify(value);
    },
    read(value) {
      return JSON.parse(value || {});
    }
  }
});

export default function useProfile() {
  const profileUser = ref(null);
  const profileAll = ref(null);
  const isUpdated = ref(false);
  const onGet = () => useApi((params) => get(params));
  const onGetUser = (user) =>
    getProfile(user).then((res) => {
      profileUser.value = res.data.profile;
      profileAll.value = res.data;
    });

  const onUpdate = (payload, user_id) =>
    update(payload, user_id).then(() => (isUpdated.value = true));
  return {
    profile: profile,
    profileUser: profileUser,
    profileAll: profileAll,
    onGet,
    onUpdate,
    onGetUser,
    isUpdated
  };
}
