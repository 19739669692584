import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import { isAuthentificated } from '@/composables/auth';
import { computed } from 'vue';
import store from '@/store';

const userType = computed(() => store.state?.profileStore?.type);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { header: 'primary' }
    // beforeEnter: checkExecutor,
  },
  {
    path: '/:categorySlug',
    name: 'order.index',
    component: () => import(/* webpackChunkName: "order-index" */ '../views/order/IndexView.vue'),
    props: true,
    children: [
      {
        path: ':serviceSlugs*',
        name: 'order.create',
        component: () =>
          import(/* webpackChunkName: "order-create" */ '../views/order/CreateView.vue'),
        props: true
      },
      {
        path: '/:categorySlug/:serviceSlugs+/:form',
        name: 'order.update',
        component: () =>
          import(/* webpackChunkName: "order-update" */ '../views/order/UpdateView.vue'),
        props: true
      }
    ]
  },
  {
    path: '/order/:orderId',
    name: 'order.view',
    component: () => import(/* webpackChunkName: "order-view" */ '../views/OrderView.vue'),
    props: true
  },
  {
    path: '/direct/order',
    name: 'direct_order',
    component: () => import(/* webpackChunkName: "direct-order" */ '../views/direct/OrderList.vue')
  },
  {
    path: '/direct/order/:orderId',
    name: 'direct_order_view',
    component: () =>
      import(/* webpackChunkName: "direct-order-view" */ '../views/direct/OrderView.vue'),
    props: (router) => ({
      orderId: router.params.orderId
    })
  },
  {
    path: '/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "account" */ '../views/account/AccountView.vue')
    // beforeEnter(to, from) {
    //   if (!isAuthentificated.value)
    //     return false
    // },
  },
  {
    path: '/profile/:userId',
    name: 'profile',
    component: () =>
      import(/* webpackChunkName: "ProfileView" */ '../views/profile/ProfileView.vue')
  },
  {
    path: '/feedbacks/:userId',
    name: 'feedbacks',
    component: () => import(/* webpackChunkName: "FeedbackPage" */ '../views/FeedbackPage.vue')
  },
  {
    path: '/executors',
    name: 'executors',
    component: () => import(/* webpackChunkName: "orders" */ '../views/ExecutorsView.vue')
  },
  {
    path: '/orders',
    name: 'orders',
    component: () =>
      import(/* webpackChunkName: "orders" */ '../views/account/orders/OrdersView.vue')
  },
  {
    path: '/orders/map',
    name: 'map',
    component: () =>
      import(/* webpackChunkName: "orders/map" */ '../views/account/orders/OrdersMap.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about/AboutView.vue')
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import(/* webpackChunkName: "legal" */ '../views/LegalView.vue')
  },
  {
    path: '/legal/:slug',
    name: 'legal_view',
    component: () => import(/* webpackChunkName: "legal" */ '../views/legal/View.vue'),
    props: (router) => ({
      slug: router.params.slug
    })
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "faq" */ '../views/FaqView.vue')
  },
  {
    path: '/:slug/services',
    name: 'services',
    component: () => import(/* webpackChunkName: "services" */ '../views/ServicesView.vue'),
    props: true
  },
  {
    path: '/services-all',
    name: 'services-all',
    component: () => import(/* webpackChunkName: "services-all" */ '../views/ServicesAll.vue'),
    props: true
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/contact/ContactView.vue')
  },
  {
    path: '/usage',
    name: 'usage',
    component: () => import(/* webpackChunkName: "usage" */ '../views/UsageAgreementsView.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/auth/SignUpView.vue')
  },
  {
    path: '/forcustomers',
    name: 'forcustomers',
    component: () => import(/* webpackChunkName: "forcustomers" */ '../views/ForCustomersView.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/blog/BlogView.vue')
  },
  {
    path: '/blog/category/:category',
    name: 'blog_category',
    component: () =>
      import(/* webpackChunkName: "blog_category" */ '../views/blog/BlogCategory.vue'),
    props: (router) => ({
      category: router.params.category
    })
  },
  {
    path: '/blog/:slug',
    name: 'blog_item',
    component: () => import(/* webpackChunkName: "blog" */ '../views/blog/BlogItemView.vue'),
    props: (router) => ({
      slug: router.params.slug
    })
  },
  {
    path: '/post/:slug',
    name: 'post',
    component: () => import(/* webpackChunkName: "post" */ '../views/PostView.vue')
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import(/* webpackChunkName: "wallet" */ '../views/WalletView.vue')
  },
  {
    path: '/wallet/success',
    name: 'wallet_success',
    component: () => import(/* webpackChunkName: "wallet_success" */ '../views/WalletSuccess.vue')
  },
  {
    path: '/wallet/failed',
    name: 'wallet_failed',
    component: () => import(/* webpackChunkName: "wallet_failed" */ '../views/WalletFailed.vue')
  },
  {
    path: '/bonus',
    name: 'bonus',
    component: () => import(/* webpackChunkName: "bonus" */ '../views/BonusView.vue')
  },
  {
    path: '/support',
    name: 'support',
    component: () => import(/* webpackChunkName: "support" */ '../views/SupportView.vue')
  },
  {
    path: '/patent',
    name: 'patent',
    component: () => import(/* webpackChunkName: "patent" */ '../views/PatentView.vue')
  },
  {
    path: '/termsofuse',
    name: 'termsofuse',
    component: () => import(/* webpackChunkName: "termsofuse" */ '../views/TermsOfUseView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/NotFoundView.vue'),
    props: (router) => ({
      message: router.params.message,
      description: router.params.description
    })
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import(/* webpackChunkName: "notifications" */ '../views/account/notifications.vue')
  },
  {
    path: '/to-profile/:userId',
    name: 'to_profile',
    component: () =>
      import(/* webpackChunkName: "ProfileTo" */ '../views/profile/ProfileTo.vue')
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    document.getElementById('page').scrollIntoView();
  }
});
router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    'home',
    'support',
    'legal',
    'legal_view',
    'faq',
    'blog',
    'blog_category',
    'about',
    'contact',
    'order.index',
    'order.create',
    'executors',
    'services',
    'services-all',
    'usage',
    'signup',
    'blog_item',
    'post',
    'patent',
    'termsofuse',
    '404',
    'profile',
    'to_profile',
    'feedbacks'
  ];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = isAuthentificated.value;
  if (to.name === 'orders' && authRequired) {
    next();
  }
  if (/^(order.)/.test(to.name) && userType.value !== 'executor') {
    return next();
  }
  if (authRequired && !loggedIn) {
    !publicPages;
    return next('/');
  }
  if (/^(order.)/.test(to.name) && userType.value === 'executor' && to.name !== 'order.view') {
    return next('/');
  }

  next();
});
export default router;
