/* eslint-disable no-undef */
import axios from '@/utils/axios';

export const login = async (credentials = { username, password, type }) => {
  return axios.post('/fika/workbase/v1.0.1/auth/login', credentials);
};
export const register = async (
  credentials = {
    name,
    surname,
    patronymic,
    username,
    email,
    password,
    password_confirmation,
    type
  }
) => {
  return axios.post('/fika/workbase/v1.0.1/auth/register', credentials);
};
export const phoneCheck = async (phone) => {
  return axios.get(`/fika/workbase/sms/check?phone=${phone}`);
};
export const sendSms = async (credentials) => {
  return axios.post('/fika/workbase/sms/send', credentials, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};
export const changePass = async (credentials) => {
  return axios.post('/fika/workbase/v1.0.1/auth/password', credentials);
};
export const checkSms = async (credentials) => {
  return axios.post('/fika/workbase/sms/verify', credentials, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};
export const logout = async () => {
  return axios.get('/fika/workbase/v1.0.1/auth/logout');
};

export const refresh = async () => {
  return axios.get('/fika/workbase/v1.0.1/auth/refresh');
};
