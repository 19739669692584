<script setup>
import CCard from './c-card';
import useCategoriesApi from '@/composables/categories/collection';
import { onMounted } from 'vue';
const { isLoading, categories, onGetWhenNotLoaded } = useCategoriesApi();

onMounted(async () => {
  await onGetWhenNotLoaded({ with: ['image', 'priority_services'], count: ['services'] });
});
</script>
<template>
  <section id="asd" class="flex w-full items-center justify-center md:py-12 sm:py-8 py-4">
    <div class="container px-4 2xl:px-20 mx-auto mb-4">
      <div class="text-center sm:text-left sm:pb-8 pb-4">
        <h2 class="lg:text-2xl text-xl md:text-3xl text-gray-900 font-medium" v-html="$t('home.comfort')" />
      </div>
      <div
        v-if="isLoading"
        class="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-4 gap-7 py-5"
      >
        <div v-for="index in 8" :key="index" class="p-4 bg-white animate-pulse rounded-lg">
          <div class="flex items-center justify-around mb-10">
            <div class="w-12 h-12 rounded-full bg-gray-200" />
            <div class="w-3/5 h-12">
              <div class="w-full h-4 bg-gray-200 rounded-lg mb-3" />
              <div class="w-3/5 h-4 bg-gray-200 rounded-lg" />
            </div>
          </div>
          <div>
            <div class="w-full h-4 mb-4 bg-gray-200 rounded-lg" />
            <div class="w-3/5 h-4 mb-4 bg-gray-200 rounded-lg" />
            <div class="w-4/5 h-4 mb-4 bg-gray-200 rounded-lg" />
            <div class="w-full h-4 mb-4 bg-gray-200 rounded-lg" />
            <div class="w-2/4 h-4 mb-4 bg-gray-200 rounded-lg" />
            <div class="w-3/4 h-4 mb-4 bg-gray-200 rounded-lg" />
          </div>
          <div class="border-t pt-4 border-dashed border-gray-400">
            <div class="w-4/5 h-4 mb-4 bg-gray-200 rounded-lg" />
          </div>
        </div>
      </div>
      <div v-if="!isLoading" class="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-4 sm:py-4 py-0">
        <c-card
          v-for="category in categories"
          :key="category.id"
          :name="category.name"
          :slug="category.slug"
          :services="category.priority_services"
          :count="category.services_count"
          :icon-path="category.image?.path"
        />
      </div>
    </div>
  </section>
</template>
