<template>
  <!-- TODO: в дальнейшем для темной темы будем использовать этот цвет "dark:bg-slate-900" -->
  <div
    ref="scrollContent"
    @scroll="scrolling"
    class="min-h-screen max-h-screen overflow-auto bg-gray-100 flex flex-col scroll-smooth"
    :class="scrollClass"
  >
    <app-header />
    <router-view id="page" class="flex-1 scroll-mt-96" />
    <app-footer class="mt-auto" />
  </div>
</template>
<script setup>
import AppHeader from '@/components/headers/AppHeader.vue';
import AppFooter from '@/components/footer/AppFooter.vue';
import useSetttings from '@/composables/settings.js';
import { onMounted, ref, watch } from 'vue';
//import { usePusher } from '@/composables/pusher';
import useProfile from './composables/profile';
//import { isAuthentificated } from './composables/auth';
// import { PrivateChannel } from 'pusher-js';
import { useScroll } from '@vueuse/core';
import store from './store/index';
import { socket } from '@/socketio';
import { useToast } from 'vue-toastification';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCGs2C3yGITzlx0WUQYtNcOSGJrYqZMirI",
  authDomain: "workbase-team.firebaseapp.com",
  projectId: "workbase-team",
  storageBucket: "workbase-team.appspot.com",
  messagingSenderId: "998505725927",
  appId: "1:998505725927:web:6d68d34789866b5c79c742"
};

// Инициализация Firebase
initializeApp(firebaseConfig);
const toast = useToast();
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const { exec, isLoaded } = useSetttings();

const scrollContent = ref(null);
const scrollProps = useScroll(scrollContent);
store.commit('changeScrollProps', scrollProps);

let oldScroll = 0;
let scrollClass = ref("toNormal");
const scrolling = (e) => {
  const scrollTop = e.target.scrollTop
  oldScroll = (scrollTop - oldScroll > 80) ? scrollTop - 1 : oldScroll
  oldScroll = (oldScroll - scrollTop > 80) ? scrollTop + 1 : oldScroll
  // console.log("scrollTop", scrollTop);
  // console.log("oldScroll", oldScroll);
  scrollClass.value = scrollTop <= 104 ? "toNormal" : scrollTop >= oldScroll ? "toDown" : "toUp";
  // console.log("oldScroll", scrollClass.value);
}

onMounted(async () => {
  if (!isLoaded.value) await exec();
});

//const { pusher } = usePusher();
// onMounted(async () => {
// 	const channel = pusher.subscribe("presence-users");
// 	channel.bind("pusher:subscription_succeeded", (members) =>
// 	);
// });

const { profile = {} } = useProfile();

const subscribe = () => {
  //const channel = pusher.subscribe(`private-user.notifications.${profile.value?.id}`);

  
 // channel.bind('conversation-created', (data) => {});
 if(!socket.connected){
   socket.connect()
 }
 
 socket.emit('connect_user', { username: profile.value?.id, active: 'online', mes: '' });
 socket.emit('subscribe', { room: `notif_${profile.value?.id}` });
    socket.on('notif', function (data) {
      if (data.message) {
          store.dispatch('requestgetNotification');        
          toast.success(t('notif.'+data.notif+'_notif'), {
            timeout: 10000,
            position: "top-center",
          });          
      }    
    });
};
const unsubscribe = () => {
  /*pusher.allChannels().forEach((channel) => {
    if (channel.name.startsWith('private')) {
      pusher.unsubscribe(channel.name);
    }
  });*/
  socket.disconnect()
};

onMounted(() => {
  if (profile.value?.id > 0) {
    subscribe();
   
  }else{
   // unsubscribe();
  }
});
watch(profile, () => {
  if (profile.value?.id > 0) {
   //subscribe();
     
  }else{
    unsubscribe();
  }   
});

 const messaging = getMessaging();
onMessage(messaging, (payload) => {
  /* eslint-disable */
  console.log('Message received. ', payload);
   store.dispatch('requestgetNotification'); 
   if (Notification.permission === "granted") {
    // Если разрешение на уведомления уже предоставлено
    const notification = new Notification(payload.notification.title, {
      body: payload.notification.body,
      icon: payload.notification.icon // Если у вас есть иконка уведомления
    });
    
    // Обработка клика на уведомление, например, переход на определенную страницу
    const link = payload.fcmOptions.link
    notification.onclick = function() {
      if (link) {
        window.location.href = link;
      } else {
        // Если ссылка не задана, переходим на главную страницу или на любую другую страницу по вашему усмотрению
        window.location.href = "/";
      }
    };
  } else {
    // Если разрешение на уведомления не предоставлено, запрашиваем его
    Notification.requestPermission().then(permission => {
      if (permission === "granted") {
        // Если разрешение предоставлено, повторно отправляем уведомление
        new Notification(payload.notification.title, {
          body: payload.notification.body,
          icon: payload.notification.icon // Если у вас есть иконка уведомления
        });
      }
    });
  }
  // ...
});

getToken(messaging, { vapidKey: 'BIXhs9ZB-q-a2DochSWHm71rMBV1vxQN4ZtyS3B_55F14noj1EQ5EEoqU7Df2_izE1CZzmJnn-KrSzqW-pfkiNM' }).then((currentToken) => {
  if (currentToken) {
    
    // Send the token to your server and update the UI if necessary
    /* eslint-disable */
    console.log("Token is:",currentToken);
    localStorage.setItem('user_token', currentToken);
    // ...
  } else {
    // Show permission request UI
    /* eslint-disable */
    console.log('No registration token available. Request permission to generate one.');
    // ...
  }
}).catch((err) => {
  /* eslint-disable */
  console.log('An error occurred while retrieving token. ', err);
  // ...
});

  
</script>
